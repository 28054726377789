.business_feature,
.technology_feature {
  position: relative;
  
  .sec_ttl_en {
    left: 50%;
    @include responsive((
      margin-left: -600,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  &_info {
    box-sizing: border-box;
    margin: 0 auto;
    @include responsive((
      width: 996,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0);
    }
  }

  &_ttl {
    font-weight: 500;
    text-align: center;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(40);
    }
  }

  &_desc {
    p {
      @include responsive((
        font-size: 18,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_maker {
    @include responsive((
      padding-top: 120,
    ));
    @include mq(sp) {
      padding-top: mycalcSP(64);
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      box-sizing: border-box;
      @include responsive((
        padding: 64 24 0,
      ));
      @include mq(sp) {
        padding: mycalcSP(32 0 0);
        justify-content: space-between;
      }

      &_item {
        box-sizing: border-box;
        position: relative;
        @include responsive((
          width: 384,
          margin-bottom: 16,
          padding-left: 44,
          font-size: 18,
          line-height: 32,
        ));
        @include mq(sp) {
          width: mycalcSP(161);
          margin-bottom: mycalcSP(12);
          padding-left: mycalcSP(22);
          font-size: mycalcSP(15);
          line-height: mycalcSP(18);
        }

        &:before {
          content: "";
          position: absolute;
          display: block;
          border-radius: 50%;
          background: $c_red;
          @include responsive((
            width: 8,
            height: 8,
            top: 14,
            left: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(8);
            height: mycalcSP(8);
            top: mycalcSP(6);
            left: mycalcSP(8);
          }
        }

        a, span {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
      }
    }
  }

  &_next {
    &_bg {
      background-image: url("../images/business/domestic-sales/feature_bg.jpg");
      @include mq(sp) {
        background-image: url("../images/business/domestic-sales/feature_bg_sp.jpg");
      }
    }
  }

}


.business_focus {
  position: relative;

  &_info {
    box-sizing: border-box;
    margin: 0 auto;
    @include responsive((
      width: 996,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0);
    }
  }

  &_ttl {
    font-weight: 500;
    text-align: center;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(40);

    }
  }

  &_desc {
    p {
      @include responsive((
        font-size: 18,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_next {
    &_bg {
      background-position: bottom center;
      background-image: url("../images/business/domestic-sales/contact_bg.jpg");

      @include mq(sp) {
        background-image: url("../images/business/domestic-sales/contact_bg_sp.jpg");
      }
    }

    .btn_round {
      @include responsive((
        width: 224,
        height: 56,
      ));
      @include mq(sp) {
        width: mycalcSP(196);
        height: mycalcSP(56);
      }
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include responsive((
      margin-top: 80,
    ));
    @include mq(sp) {
      flex-flow: column;
      margin-top: mycalcSP(48);
    }

    &_item {
      @include responsive((
        width: 360,
        margin-right: 60,
      ));
      @include mq(sp) {
        width: 100%;
        margin-right: auto;
        margin-bottom: mycalcSP(32);

        &:last-child {
          margin-bottom: 0;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
        @include mq(sp) {
          margin-right: auto;
        }
      }

      .half & {
        @include responsive((
          width: 546,
          margin-right: 108,
        ));
        @include mq(sp) {
          width: 100%;
          margin-right: auto;
          margin-bottom: mycalcSP(32);

          &:last-child {
            margin-bottom: 0;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
          @include mq(sp) {
            margin-right: auto;
          }
        }
      }

      &_figure {
        overflow: hidden;
        background: $c_hyacinth_light;
        margin: 0 auto;
        @include responsive((
          width: 360,
          height: 240,
        ));
        @include mq(sp) {
          width: mycalcSP(342);
          height: mycalcSP(228);
        }

        &_img {
          display: block;
          width: 100%;
          height: auto;
        }

        .half & {
          @include responsive((
            width: 546,
            height: 364,
          ));
          @include mq(sp) {
            width: mycalcSP(342);
            height: mycalcSP(228);
          }
        }
      }

      &_desc {
        @include responsive((
          font-size: 16,
          line-height: 32,
          margin-top: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
          margin-top: mycalcSP(16);
        }
      }
    }
  }
}


.business_faq {
  position: relative;

  .sec_ttl_en {
    width: 100%;
    text-align: center;
    left: 0;
    @include responsive((
      margin-left: 0,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  &_desc {
    p {
      text-align: center;
      @include responsive((
        font-size: 18,
        line-height: 40,
      ));
      @include mq(sp) {
        text-align: left;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_list {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid $c_gray;
    @include responsive((
      margin-top: 152,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(48);
    }

    &_item {
      width: 100%;
      border-top: 1px solid $c_gray;
      position: relative;
      box-sizing: border-box;
      @include responsive((
        padding: 56 102,
      ));
      @include mq(sp) {
        padding: mycalcSP(32 0 24 0);
      }

      &:before {
        content: "";
        position: absolute;
        border-top: 1px solid $c_red;
        top: -1px;
        left: 0;
        @include responsive((
          width: 96,
        ));
        @include mq(sp) {
          width: mycalcSP(64);
        }
      }

      &_q {
        cursor: pointer;
        @include responsive((
          padding: 4 0,
        ));
        @include mq(sp) {
          position: relative;
          padding: mycalcSP(0 48 0 40);
        }

        &:before {
          content: "Q";
          font-family: $f_en;
          position: absolute;
          top: 0;
          left: 0;
          @include responsive((
            font-size: 40,
            line-height: 40,
            top: 0,
            left: -102,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(24);
            top: mycalcSP(0);
            left: mycalcSP(0);
          }
        }

        &_txt {
          font-weight: bold;
          @include responsive((
            font-size: 22,
            line-height: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(16);
            line-height: mycalcSP(24);
          }
        }
      }

      &_a {
        @include responsive((
          font-size: 16,
          line-height: 32,
          padding-top: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
          padding-top: mycalcSP(24);
        }
      }
    }
  }
}


.js-slideBtn {
  position: relative;

  .btn_plus {
    position: absolute;
    margin: 0;
    @include responsive((
      width: 56,
      height: 56,
      top: -8,
      right: -100,
    ));
    @include mq(sp) {
      width: mycalcSP(40);
      height: mycalcSP(40);
      top: mycalcSP(0);
      right: mycalcSP(0);
    }

    &_link {
      display: block;
      width: 100%;
      height: 100%;

      .ico_circle {
        width: 100%;
        height: 100%;

        circle {
          stroke-width: 2px;
          opacity: 1;
          stroke: $c_gray;
          transition: opacity $t_btn, stroke-dashoffset $t_btn;
          stroke-dashoffset: 0;
          stroke-dasharray: 380;
          /* // 1280以下で一部切れてしまうので固定値で設定
          @include responsive((
            stroke-dasharray: 760,
          ));
          */
          @include mq(sp) {
            stroke-dasharray: mycalcSP(760);
          }
        }
      }

      .ico_plus {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform $t_btn;
        transform: scale(1) rotate(0);
        @include responsive((
          width: 16,
          height: 16,
          margin-left: -8,
          margin-top: -8,
        ));
        @include mq(sp) {
          width: mycalcSP(16);
          height: mycalcSP(16);
          margin-top: mycalcSP(-8);
          margin-left: mycalcSP(-8);
        }

        &_v {
          transition: transform $t_btn;
          transform-origin: center center;
        }
      }

      &:hover {
        .ico_plus {
          transform: scale(1.066) rotate(-180deg);
        }

        .ico_circle circle {
          stroke-dashoffset: 760;
          /* // 1280以下で一部切れてしまうので固定値で設定
          @include responsive((
            stroke-dashoffset: 1520,
          ));
          */
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(1520);
          }
        }
      }
    }
  }

  &.is-open {
    .ico_plus_v {
      transform: scaleY(0);
    }
  }
}


.js-slideCnt {
  display: none;
}


.technology_merit,
.technology_type,
.business_feature,
.technology_feature,
.business_focus {
  &_next {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;
    @include responsive((
      height: 320,
      margin-top: 120,
    ));
    @include mq(sp) {
      height: mycalcSP(204);
      margin-top: mycalcSP(64);
    }

    &_ttl {
      color: $c_white;
      font-weight: 500;
      @include responsive((
        font-size: 24,
        line-height: 40,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(18);
        line-height: mycalcSP(28);
        margin-bottom: mycalcSP(24);
      }
    }

    &_bg {
      position: absolute;
      overflow: hidden;
      background-color: $c_hyacinth_light;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-position: top center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
      @include mq(sp) {
        background-position: center center;
        background-attachment: local;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}