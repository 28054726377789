.contact,
.catalog {

  &_tel {
    background: $c_white;
    margin: 0 auto;
    box-sizing: border-box;
    @include responsive((
      width: 1200,
      padding: 40,
      margin-bottom: 96,
    ));
    @include mq(sp) {
      width: mycalcSP(342);
      padding: mycalcSP(32);
      margin-bottom: mycalcSP(48);
    }

    &_ttl {
      width: 100%;
      font-weight: 500;
      text-align: center;
      @include responsive((
        font-size: 24,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(18);
        line-height: mycalcSP(28);
        margin-bottom: mycalcSP(24);
      }
    }

    &_schedule {
      width: 100%;
      font-weight: 500;
      text-align: center;
      @include responsive((
        font-size: 14,
        line-height: 24,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(14);
        line-height: mycalcSP(24);
        margin-bottom: mycalcSP(24);
      }
    }

    &_list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      @include mq(sp) {
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
      }

      &_item {
        box-sizing: border-box;
        border: 1px solid $c_gray;
        text-align: center;
        @include responsive((
          width: 384,
          padding: 24 24 32,
        ));
        @include mq(sp) {
          width: mycalcSP(280);
          padding: mycalcSP(16 24 24);
        }
        &:first-child {
          @include responsive((
            margin-right: 64,
          ));
          @include mq(sp) {
            margin-right: 0;
            margin-bottom: mycalcSP(24);
          }
        }

        &_ttl {
          width: 100%;
          font-weight: 500;
          text-align: center;
          border-bottom: 1px solid $c_gray;
          @include responsive((
            font-size: 18,
            line-height: 24,
            padding-bottom: 16,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(16);
            line-height: mycalcSP(20);
            padding-bottom: mycalcSP(16);
            margin-bottom: mycalcSP(16);
          }
        }

        &_num {
          width: 100%;
          text-align: center;
          font-family: $f_en;
          @include responsive((
            font-size: 24,
            line-height: 24,
          ));
          @include mq(sp) {
            font-size: mycalcSP(20);
            line-height: mycalcSP(20);
          }
        }
      }
    }
  }

  .more_area {
    @include responsive((
      margin-top: 96,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(48);
    }

    .btn_round {
      @include responsive((
        margin: 96 auto 0,
      ));
      @include mq(sp) {
        margin: mycalcSP(48 auto 0);
      }

      .btn_round_link {

        padding: 0;

        input[type="submit"] {
          text-align: center;
          color: $c_white;
          @include responsive((
            padding: 16 64,
            border-radius: 36,
          ));
          @include mq(sp) {
            width: 100%;
            box-sizing: border-box;
            border-radius: mycalcSP(36);
            flex-flow: column;
            padding: mycalcSP(16);
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.back_btn {
  display: none;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .mw_wp_form_confirm & {
    display: flex;
  }

  .btn_circle {
    position: absolute;
  }

  .btn_link_txt {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-left: 0;
    @include responsive((
      padding: 18 0 18 72,
    ));
    @include mq(sp) {
      padding: mycalcSP(10 0 10 48);
    }
  }

  .prev_link_txt {
    @include responsive((
      font-size: 16,
      line-height: 20,
    ));
    @include mq(sp) {
      font-size: mycalcSP(15);
      line-height: mycalcSP(20);
    }
  }
}

.privacy_check_txt {
  a {
    color: $c_red;
  }
}

.confirmation {
  .more_area {
    @include responsive((
      margin-top: 24,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(24);
    }
  }

  .back_btn {
    @include responsive((
      margin-bottom: 96,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(48);
    }
  }
}
