.news {

  &_archive {

    &_inner {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin: 0 auto;
      box-sizing: border-box;

      @include responsive((
        width: 1280,
        padding: 0 40,
        margin-bottom: 264,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(0 24);
        margin-bottom: mycalcSP(120);
      }

    }
  }

  .archive_list {
    width: 100%;
  }

  &_archive_tax {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @include responsive((
      margin-bottom: 96,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(36);
    }


    &_item {
      color: $c_text;
      overflow: hidden;
      border: 1px solid $c_gray;
      @include responsive((
        border-radius: 28,
        margin-right: 16,
      ));
      @include mq(sp) {
        border-radius: mycalcSP(28);
        margin-right: mycalcSP(12);
        margin-bottom: mycalcSP(12);
      }

      &_txt,
      &_link {
        color: inherit;
        display: flex;
        @include responsive((
          font-size: 16,
          line-height: 16,
          padding: 12 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(13);
          line-height: mycalcSP(13);
          padding: mycalcSP(10 24);
        }
      }

      &.selected {
        color: $c_red;
        border-color: $c_red;
      }
    }
  }
}