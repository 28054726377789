/*
 * header.scss
 *
 * ヘッダー用コンポーネント
 */

.header {
  width: 100vw;
  position: fixed;
  z-index: 101;
  transition: transform $t_fade, background-color $t_fade, border-bottom $t_fade;
  display: flex;
  box-sizing: border-box;
  padding: 0;
  border-bottom: 1px solid $c_gray;
  justify-content: flex-end;
  align-items: center;
  @include responsive((
    padding: 32 0,
  ));
  @include mq(sp) {
    padding: 0;
    flex-flow: column;
    border-bottom: 0;
  }

  &.negative,
  &.open {
    border-bottom-color: $c_white;
  }
}


.header_logo {
  flex-shrink: 0;
  opacity: 1;
  z-index: 10;
  transition: transform $t_fade, opacity $t_fade;
  transform-origin: left center;
  position: absolute;
  top: 50%;
  @include responsive((
    width: 218,
    height: 48,
    left: 80,
    margin-top: -24,
  ));
  @include mq(sp) {
    width: 100%;
    height: mycalcSP(72);
    top: 0;
    margin-top: 0;
    left: 0;
    padding: 0;
    position: relative;
    border-bottom: 1px solid $c_white;
    transform-origin: left top;
    transition: transform $t_fade 0s, opacity $t_fade .2s;
  }

  &_link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include mq(sp) {
      width: mycalcSP(146);
      height: mycalcSP(32);
      position: absolute;
      top: mycalcSP(20);
      left: mycalcSP(20);
    }


    picture, svg, img {
      max-width: none;
      width: auto;
      height: 100%;
      display: block;
    }
  }

  .ico_logo circle,
  .ico_logo path {
    fill: $c_red;
    transition: fill $t_scroll;

    .negative & {
      fill: $c_white;
    }
    .open & {
      fill: $c_red;
    }
  }

  .scrolled & {
    transform: scale(.75);

    @include mq(sp) {
      opacity: 0;
      pointer-events : none;
    }
  }

  .loading .scrolled & {
    transform: scale(1); // ページ遷移時のループ挙動を回避
  }
}


.header_content {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq(sp) {
    width: 100%;
    height: 100vh; // fallback
    height: calc((var(--vh, 1vh) * 100));
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: scroll;
    flex-flow: column;
    justify-content: center;
    padding-top: mycalcSP(112);
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
  }
}


.header_gnav {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  position: relative;
  transform: translateY(0);
  transition: transform $t_scroll;
  @include responsive((
    height: 56,
    margin-right: 48,
  ));
  @include mq(sp) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding: mycalcSP(48 0 40);
    position: relative;
    color: $c_text;
    margin-right: 0;
    box-sizing: border-box;
    transition: opacity 1.0s $e_curtain 0s, transform 1s $e_curtain 1s;
    z-index: 9;
  }

  &_list {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include flex(row, center, center, none, wrap);
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 0;
      padding: mycalcSP(0 24);
      background: none;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_item {
      @include responsive((
        margin: 0 20,
      ));
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        overflow: hidden;
        position: relative;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        .header_gnav_list_item_link {
          @include mq(sp) {
            border-bottom: 1px solid $c_gray;
          }
        }
      }

      &_link {
        line-height: 1;
        font-weight: bold;
        position: relative;
        transition: background-color $t_slide, color $t_scroll;
        box-sizing: border-box;
        color: $c_black;
        @include flex(row, center, center, none, wrap);
        @include responsive((
          font-size: 16,
          line-height: 24,
          padding: 14 4,
        ));
        @include mq(sp) {
          font-weight: 600;
          justify-content: space-between;
          font-size: mycalcSP(15);
          line-height: mycalcSP(22);
          padding: mycalcSP(24 0);
          height: auto;
          text-align: left;
          border-top: 1px solid $c_gray;
        }
        @include minq(sp) {
          .negative & {
            color: $c_white;
          }
        }

        .parent & {
          @include mq(sp) {
            padding-bottom: mycalcSP(16);

            &:before,
            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: mycalcSP(34);
              width: mycalcSP(14);
              height: 1px;
              background: $c_red;
              transition: transform $t_btn;
            }

            &:after {
              transform: rotate(90deg);
            }
          }
        }

        .parent &.open {
          &:before {
            transform: rotate(90deg) scale(0);
          }
          &:after {
            transform: rotate(180deg);
          }
        }

        &_txt {
          letter-spacing: inherit;
          font-weight: inherit;
        }

        .dot {
          position: absolute;
          top: 0;
          left: 50%;
          border-radius: 50%;
          pointer-events: none;
          opacity: 0;
          animation: Dot-Rotation-Rev $p_hv $e_slot forwards;
          @include responsive((
            width: 200,
            height: 200,
            margin-left: -100,
          ));

          &:before {
            content: "";
            position: absolute;
            background: $c_red;
            // opacity: 0;
            top: 0;
            left: 50%;
            transition: top $t_slide, height $t_slide, opacity $t_slide;
            @include responsive((
              width: 6,
              height: 6,
              margin-left: -3,
              border-radius: 3,
            ));
            @include mq(sp) {
              width: mycalcSP(6);
              height: mycalcSP(6);
              margin-left: mycalcSP(-3);
              border-radius: mycalcSP(3);
            }
          }
        }

        @include minq(sp) {
          &:hover {
            .dot {
              animation: Dot-Rotation $p_hv $e_slot forwards;
            }
          }
        }
      }
    }

    &_child {
      position: absolute;
      left: 50%;
      visibility: hidden;
      opacity: 0;
      @include responsive((
        width: 1200,
        margin-left: -600,
        top: 50,
        padding-top: 14,
      ));
      @include minq(sp) {
        pointer-events: none;

        &.open {
          pointer-events: auto;
        }
      }
      @include mq(sp) {
        width: 100%;
        height: 0;
        margin-left: 0;
        position: relative;
        top: 0;
        left: 0;
        padding: 0;

        &.open {
          padding: mycalcSP(0 0 24);
        }
      }

      &_content {
        background: $c_white;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        @include responsive((
          min-height: 400,
          padding: 64 64 96,
        ));
        @include mq(sp) {
          padding: 0;
          min-height: auto;
        }
      }

      &_ttl {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-shrink: 0;
        @include responsive((
          width: 446,
        ));
        @include mq(sp) {
          display: none;
        }

        &_jp {
          position: relative;
          display: block;
          @include responsive((
            font-size: 14,
            line-height: 14,
            margin-bottom: 16,
            padding-left: 14,
          ));

          &:before {
            content: "";
            display: block;
            border-radius: 50%;
            background: $c_red;
            top: 50%;
            left: 0;
            position: absolute;
            @include responsive((
              width: 6,
              height: 6,
              margin-top: -3,
            ));
          }
        }
        &_en {
          display: block;
          font-family: $f_en;
          @include responsive((
            font-size: 36,
            line-height: 36,
          ));
        }
      }

      &_list {
        border-left: 1px solid $c_gray;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        @include responsive((
          padding-left: 48,
        ));
        @include mq(sp) {
          border-left: 0;
          padding-left: mycalcSP(24);
          flex-flow: column;
        }

        &_item {
          @include responsive((
            width: 200,
            margin-right: 32,
            margin-bottom: 32,
          ));
          @include mq(sp) {
            width: 100%;
            margin-right: 0;
            margin-bottom: mycalcSP(8);
          }

          &:last-child {
            margin-right: 0;
            @include mq(sp) {
              margin-bottom: 0;
            }
          }

          &_link {
            font-weight: 400;
            @include responsive((
              font-size: 16,
              line-height: 24,
            ));
            @include mq(sp) {
              font-size: mycalcSP(14);
              line-height: mycalcSP(24);
              color: $c_black;
              text-align: left;
            }
          }

          &_txt {
            letter-spacing: inherit;
            font-weight: inherit;
            text-align: left;
          }

          &_img {
            background: $c_hyacinth;
            overflow: hidden;
            @include responsive((
              width: 200,
              height: 200,
              margin-bottom: 16,
            ));
            @include mq(sp) {
              display: none;
            }

            img {
              display: block;
            }
          }
        }
      }

      .btn {
        position: absolute;
        @include responsive((
          top: 178,
          left: 64,
        ));
        @include mq(sp) {
          display: none;
        }
      }
    }
    
    &_product {
      .header_gnav_list_child_list {
        &_item {
          @include responsive((
            width: 160,
          ));
          @include mq(sp) {
            width: 100%;
          }

          &_link {
            @include responsive((
              font-size: 14,
              line-height: 18,
            ));
            @include mq(sp) {
              font-size: mycalcSP(14);
              line-height: mycalcSP(24);
            }
          }
          
          &_img {
            @include responsive((
              width: 160,
              height: 90,
              margin-bottom: 8,
            ));
            @include mq(sp) {
            }
          }
        }
      }
    }
  }

  &_filter {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $c_black;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 5;
    // transition: .6s $e_slot .02s;
    // transform: translateY(-110%);
    pointer-events: none;

    &.open {
      transition: .6s $e_slot;
    }

    @include mq(sp) {
      display: none;
    }
  }
}


.header_touchpoint {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;

  @include mq(sp) {
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    top: auto;
    bottom: 0;
    box-sizing: border-box;
    padding: mycalcSP(0 24);
  }

  &_cta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include responsive((
      padding-right: 80,
    ));
    @include mq(sp) {
      width: 100%;
      padding-right: 0;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      margin-bottom: mycalcSP(40);
    }

    &_btn {
      position: relative;
      @include responsive((
        margin-right: 16,
      ));
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
        margin-bottom: mycalcSP(16);
        overflow: hidden;
      }

      &:last-child {
        margin-right: 0;
        @include mq(sp) {
          margin-bottom: 0;
        }
      }

      &_catalog {
        background: $c_navy;
      }
      &_contact {
        background: $c_red;
      }

      // 外円
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        border-radius: 50%;
        box-sizing: border-box;
        background: $c_white;
        opacity: .2;
        transform: scale(0);
        @include responsive((
          width: 320,
          height: 320,
          margin-top: -160,
          margin-left: -160,
        ));
        @include mq(sp) {
          content: none;
        }
      }

      @include minq(sp) {
        &:hover:before {
          opacity: 0;
          transform: scale(1);
          transition: transform $t_btn, opacity $t_btn;
        }
      }
    }

    &_link {
      color: $c_white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 0;
      overflow: hidden;
      transition: background-color $t_btn;
      @include responsive((
        padding: 16 64,
        border-radius: 28,
      ));
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        border-radius: mycalcSP(36);
        flex-flow: column;
        padding: mycalcSP(16);
      }

      &_txt {
        font-weight: bold;
        position: relative;
        z-index: 0;
        color: $c_white;
        @include responsive((
          font-size: 16,
          line-height: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(15);
          line-height: mycalcSP(24);
        }
      }

      // 内円
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        border-radius: 50%;
        box-sizing: border-box;
        opacity: .2;
        transform: scale(0);
        @include responsive((
          width: 320,
          height: 320,
          margin-top: -160,
          margin-left: -160,
        ));
        @include mq(sp) {
          content: none;
        }
      }

      @include minq(sp) {
        &:hover:before {
          transition: transform $t_btn;
          transform: scale(1);
        }

        &.header_touchpoint_cta_btn_catalog {
          &:hover,
          &:before {
            background-color: $c_navy_light;
          }
        }

        &.header_touchpoint_cta_btn_contact {
          &:hover,
          &:before {
            background-color: $c_red_light;
          }
        }
      }
    }
  }
}


.header_copyright {
  display: none;

  @include mq(sp) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $f_en;
    font-size: mycalcSP(10);
    line-height: mycalcSP(20);
    letter-spacing: .1em;
    overflow: hidden;

    &_txt {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    }
  }
}



.hum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 50%;
  position: fixed;
  cursor: pointer;
  order: 1;
  z-index: 105;
  @include mq(sp) {
    width: mycalcSP(56);
    height: mycalcSP(56);
    top: mycalcSP(8);
    right: mycalcSP(20);
  }

  &_circle {
    display: block;
    position: absolute;
    @include mq(sp) {
      width: mycalcSP(56);
      height: mycalcSP(56);
      top: mycalcSP(-1);
      right: mycalcSP(-1);
    }

    .ico_circle {
      width: 100%;
      height: 100%;

      circle {
        transition: opacity $t_btn, stroke-dashoffset $t_btn, stroke $t_curtain;
        stroke: $c_gray;
        opacity: .5;

        .negative & {
          stroke: $c_white;
        }

        .open & {
          stroke: $c_gray;
        }
      }
    }

    &.shadow {
      transition: transform $t_btn;

      .ico_circle {
        circle {
          @include responsive((
            stroke-dashoffset: 420,
            stroke-dasharray: 420,
          ));
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(420);
            stroke-dasharray: mycalcSP(420);
          }
        }
      }
    }
  }

  &_bar {
    position: absolute;
    top: 50%;
    right: 50%;
    @include mq(sp) {
      width: mycalcSP(24);
      height: mycalcSP(7);
      margin-top: mycalcSP(-4);
      margin-right: mycalcSP(-13);
    }

    &_u,
    &_b {
      position: absolute;
      width: 100%;
      right: 0;
      transition: transform $t_btn;

      @include responsive((
        height: 1,
      ));
      @include mq(sp) {
        height: mycalcSP(1);
      }

      .ico_border {
        width: 100%;
        height: 100%;
        display: block;

        path {
          stroke: $c_text;
          transition: opacity $t_btn, stroke-dashoffset $t_btn, stroke $t_curtain;
          stroke-dashoffset: 0;
          @include responsive((
            stroke-dasharray: 24,
          ));
          @include mq(sp) {
            stroke-dasharray: mycalcSP(24);
          }

          .negative & {
            stroke: $c_white;
          }

          .open & {
            stroke: $c_text;
          }
        }
      }
    }

    &_u {
      top: 0;
    }

    &_b {
      bottom: 0;

      .ico_border path {
        transition-delay: .1s;
      }
    }
  }

  &_bg {
    content: "";
    display: block;
    position: fixed;
    background: $c_white;
    z-index: 2;
    //transition: clip-path .4s;
    will-change: transform, clip-path;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform-origin: center center;
    visibility: hidden;
    opacity: 0;

    &_filter {
      z-index: -1;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $c_black;
      opacity: 1;
      top: 0;
      left: 0;

      @include mq(sp) {
        opacity: .8;
      }
    }

    &_square {
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      @include responsive((
        width: 1920,
        height: 1080,
        margin-top: -540,
        margin-left: -960,
      ));
      @include mq(sp) {
        width: mycalcSP(390);
        height: mycalcSP(844);
        margin-top: mycalcSP(-422);
        margin-left: mycalcSP(-195);
      }

      &_img {
        @include responsive((
          width: 1920,
          height: 1080,
        ));
        @include mq(sp) {
          width: mycalcSP(390);
          height: mycalcSP(844);
        }
      }
    }

    &_arch {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      @include responsive((
        width: 520,
        height: 800,
        margin-top: -400,
        margin-left: -260,
      ));
      @include mq(sp) {
        display: none;
      }

      &_img {
        @include responsive((
          width: 520,
          height: 800,
        ));
      }
    }
  }

  &_menu {
    z-index: 1;

    svg {
      width: mycalcSP(56);
      height: mycalcSP(16);
      display: block;
      transition: $t_fade;

      path {
        fill: $c_white;
        fill-opacity: 1;
        stroke: $c_white;

        stroke-width: 0;
        stroke-dasharray: 54;
        stroke-dashoffset: 0;
        transition: fill $t_slide 1.8s,
        fill-opacity $t_fade 1.8s,
        stroke-width $t_slide 1.8s,
        stroke-dashoffset $t_slide 1s;
      }
    }
  }

  @include minq(sp) {
    &:hover {

      .hum_circle {
        circle {
          opacity: .3;
          stroke: $c_gray;
        }

        &.shadow {
          transform: rotate(135deg);

          circle {
            stroke: $c_white;
            stroke-dashoffset: 0;
            opacity: 1;
          }
        }
      }

      .hum_bar {
        path {
          stroke: $c_white;
          @include responsive((
            stroke-dashoffset: -48,
          ));
        }
      }
    }
  }
}


.open {
  .hum {
    &_bar {
      &_u,
      &_b {
        path {
          stroke: $c_black;
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(-48);
          }
        }
      }

      &_u {
        top: 50%;
        transform: translateY(-1px) rotate(30deg);
      }

      &_b {
        bottom: 50%;
        transform: translateY(1px) rotate(-30deg);
      }
    }
  }
}


.close {
  .hum {
    &_bar {
      &_u,
      &_b {
        path {
          stroke: $c_text;
          @include responsive((
            stroke-dashoffset: 0,
          ));
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(0);
          }
        }
      }
    }
  }
}


.is-fixed {
  @include mq(sp) {
    height: 100%;
    overflow: hidden;
  }
}


/*
// デザインの仕様上、不要に。
.negative_header {
  z-index: 106;
  clip-path: inset(100% 0% 0% 0%);
  height: 100vh;

  @include mq(sp) {
    z-index: 104;
    .open & {
      // 閉じれるように調整
      height: mycalcSP(88);
      z-index: 107;
    }
  }

  &_left {
    left: 0;
    @include responsive((
      width: 230
    ));
    @include mq(sp) {
      width: 50%;
    }
  }

  &_right {
    right: 0;
    @include responsive((
      width: 104
    ));
    @include mq(sp) {
      width: 50%;
    }
  }

  .header_logo {
    .ico_logo path {
      fill: $c_white;
    }
  }

  .header_reserve {
    border-color: $c_white;

    &_link {
      @include textGradient($c_white, $c_text, '.header_reserve_link_txt', $p_slide, .1s);

      &_txt {
        color: $c_white;
      }

      &:after {
        background: $c_white;
      }
    }
  }

  .header_bottom {
    .sns {
      path {
        stroke: $c_white;
      }
    }

    .lang_list_item:before,
    .lang_list_item_link {
      color: $c_white;
    }
  }

  .hum {
    z-index: 1002;

    &_circle {
      circle {
        stroke: $c_white;
        opacity: 1;
      }
    }

    &_bar {
      path {
        stroke: $c_white;

        .close & {
          stroke: $c_white;
        }
      }
    }
  }
}
*/
