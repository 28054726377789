.company_message {
  position: relative;

  .sec_ttl_en {
    left: 50%;
    @include responsive((
      margin-left: -600,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  .sec_inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &_desc {
    @include responsive((
      width: 690,
      padding-top: 48,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding-top: 0;
    }
    
    p {
      @include responsive((
        font-size: 18,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }
    }
    
    &_sign {
      text-align: right;
      @include responsive((
        margin-top: 48,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(24);
      }

      .sign {
        display: block;
        height: auto;
        margin-right: 0;
        margin-left: auto;
        @include responsive((
          width: 134,
        ));
        @include mq(sp) {
          width: mycalcSP(134);
        }
      }
    }
  }

  &_figure {
    overflow: hidden;
    order: -1;
    background: $c_hyacinth_light;
    @include responsive((
      width: 384,
      height: 512,
    ));
    @include mq(sp) {
      width: mycalcSP(240);
      height: mycalcSP(320);
      margin: 0 auto mycalcSP(32);
    }
    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}


.company_philosophy {
  position: relative;

  .sec_inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &_ttl {
    width: 100%;
    display: block;
    order: 1;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(48);
    }
  }

  &_desc {
    order: 3;
    @include responsive((
      width: 588,
      padding-bottom: 48,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 0;
    }

    p {
      @include responsive((
        font-size: 18,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }
    }
  }

  &_figure {
    overflow: hidden;
    order: 2;
    background: $c_hyacinth_light;
    @include responsive((
      width: 384,
      height: 512,
      margin-left: 102,
    ));
    @include mq(sp) {
      width: mycalcSP(240);
      height: mycalcSP(320);
      margin: 0 auto mycalcSP(32);
    }
    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}


.company_overview {
  position: relative;

  .sec_ttl_en {
    left: 50%;
    @include responsive((
      margin-left: -600,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  &_list {
    width: 100%;
    border-top: 1px solid $c_gray;
  }

  &_item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $c_gray;
    @include responsive((
      padding: 40 0,
    ));
    @include mq(sp) {
      padding: mycalcSP(24 0);
      flex-flow: column;
    }

    &_ttl {
      flex-shrink: 0;
      font-weight: 500;
      @include responsive((
        width: 282,
        font-size: 18,
        line-height: 32,
        padding-right: 24,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(16);
        line-height: mycalcSP(24);
        padding-right: 0;
        margin-bottom: mycalcSP(8);
      }
    }

    &_desc {
      @include responsive((
        font-size: 18,
        line-height: 32,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
      }

      a {
        @include targetBlank();
      }
    }
  }

  &_images {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    @include responsive((
      margin-top: 96,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(48);
      padding-top: mycalcSP(232);
    }
  }

  &_figure {
    background: $c_hyacinth_light;
    overflow: hidden;
    &:nth-child(1) {
      @include responsive((
        width: 384,
        height: 512,
        margin-top: 316,
        margin-right: 40,
      ));
      @include mq(sp) {
        width: mycalcSP(120);
        height: mycalcSP(160);
        margin-top: 0;
        margin-right: mycalcSP(24);
      }
    }
    &:nth-child(2) {
      @include responsive((
        width: 384,
        height: 512,
        margin-top: 316,
        margin-right: 164,
      ));
      @include mq(sp) {
        width: mycalcSP(120);
        height: mycalcSP(160);
        margin-top: 0;
        margin-right: mycalcSP(24);
      }
    }
    &:nth-child(3) {
      @include responsive((
        width: 948,
        height: 632,
      ));
      @include mq(sp) {
        width: mycalcSP(312);
        height: mycalcSP(208);
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}


.company_history {
  position: relative;

  &_figure {
    overflow: hidden;
    background: $c_hyacinth_light;
    margin: 0 auto;
    @include responsive((
      width: 1200,
      height: 600,
      margin-bottom: 120,
    ));
    @include mq(sp) {
      width: mycalcSP(342);
      height: mycalcSP(171);
      margin-bottom: mycalcSP(48);
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &_list {
    width: 100%;
    border-top: 1px solid $c_gray;
  }

  &_item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $c_gray;
    @include responsive((
      padding: 48 0,
    ));
    @include mq(sp) {
      padding: mycalcSP(32 0);
      flex-flow: column;
      position: relative;
    }

    &_ttl {
      font-family: $f_en;
      flex-shrink: 0;
      @include responsive((
        width: 232,
        font-size: 40,
        line-height: 40,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(26);
        line-height: mycalcSP(26);
        margin-bottom: mycalcSP(16);
      }
    }

    &_desc {
      @include responsive((
        font-size: 18,
        line-height: 32,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
      }

      &_line {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq(sp) {
          margin-top: mycalcSP(16);
        }

        .month {
          color: $c_red;
          flex-shrink: 0;
          @include responsive((
            width: 178,
          ));
          @include mq(sp) {
            width: mycalcSP(52);
            font-size: mycalcSP(18);
            line-height: mycalcSP(24);
          }
        }

        @include mq(sp) {
          &:first-child {
            margin-top: 0;

            .month {
              position: absolute;
              top: mycalcSP(34);
              left: mycalcSP(120);
            }
          }
        }
      }

      a {
        @include targetBlank();
      }
    }
  }

}


