/*
 * archive.scss
 *
 * 記事一覧用コンポーネント
 */


.archive {

  &_list {
    width: 100%;
    box-sizing: border-box;

    &_item {
      box-sizing: border-box;
      border-top: 1px solid $c_gray;

      &:last-child {
        border-bottom: 1px solid $c_gray;
      }

      &_link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        @include responsive((
          padding-top: 48,
          padding-bottom: 128,
        ));
        @include mq(sp) {
          padding-top: mycalcSP(32);
          padding-bottom: mycalcSP(80);
          flex-wrap: wrap;
        }
      }

      .tags {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: auto;
        @include responsive((
          margin-bottom: 8,
        ));
        @include mq(sp) {
          margin-top: 0;
        }
      }

      .btn_circle {
        position: absolute;
        right: 0;
        @include responsive((
          bottom: 48,
        ));
        @include mq(sp) {
          bottom: mycalcSP(32);
        }
      }

      &_link:hover {
        .title {
          text-decoration: underline;
        }
      }
    }
  }

  &_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    //flex-wrap: wrap;
  }
}

.tag,
.tag_blank {
  flex-shrink: 0;
  display: inline-block;
  transition: background-color $t_hv, color $t_hv;
  color: $c_red;
  @include responsive((
    font-size: 16,
    line-height: 24,
  ));
  @include mq(sp) {
    font-size: mycalcSP(13);
    line-height: mycalcSP(18);
  }
}

.time {
  font-family: $f_en;
  color: $c_hyacinth;
  @include responsive((
    font-size: 16,
    line-height: 24,
    margin-right: 24,
  ));
  @include mq(sp) {
    font-size: mycalcSP(13);
    line-height: mycalcSP(18);
    margin-right: mycalcSP(24);
  }
}

.title {
  width: 100%;
  display: block;
  font-weight: 500;
  @include responsive((
    font-size: 18,
    line-height: 32,
    margin-top: 24,
  ));
  @include mq(sp) {
    font-weight: bold;
    font-size: mycalcSP(15);
    line-height: mycalcSP(24);
    margin-top: mycalcSP(24);
  }
}