/*
 * headline.scss
 *
 * 見出し用コンポーネント
 * 定義する際は、接頭辞としてttl_を付けること。
 */

.headline {
  @include responsive((
    margin-bottom: 100,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(28);
  }

  &_jp {
    color: $c_text;
    font-weight: 300;
    position: relative;
    @include responsive((
      font-size: 18,
      line-height: 18,
      padding-left: 28,
      margin-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(12);
      line-height: mycalcSP(16);
      padding-left: mycalcSP(16);
      margin-bottom: mycalcSP(16);
    }

    &:before {
      content: "";
      border-radius: 50%;
      background: $c_red;
      position: absolute;
      left: 0;
      @include responsive((
        width: 12,
        height: 12,
        top: 4,
      ));
      @include mq(sp) {
        width: mycalcSP(8);
        height: mycalcSP(8);
        top: mycalcSP(4);
      }
    }
  }

  &_en {
    font-family: $f_en;
    color: $c_text;
    @include responsive((
      font-size: 80,
      line-height: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(36);
      line-height: mycalcSP(32);
    }

    ._txt {
      @include responsive((
        padding-bottom: 20,
      ));
      @include mq(sp) {
        padding-bottom: mycalcSP(20);
      }
    }
  }
}

.sec_ttl {
  width: 100%;
  text-align: center;
  font-weight: 500;
  @include responsive((
    font-size: 36,
    line-height: 56,
    margin-bottom: 80,
  ));
  @include mq(sp) {
    font-size: mycalcSP(22);
    line-height: mycalcSP(40);
    margin-bottom: mycalcSP(32);
  }

  &_sub {
    width: 100%;
    text-align: center;
    font-weight: 500;
    @include responsive((
      font-size: 32,
      line-height: 48,
      margin-bottom: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(20);
      line-height: mycalcSP(32);
      margin-bottom: mycalcSP(16);
    }
  }

  &_en {
    color: $c_white;
    font-family: $f_en;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include responsive((
      font-size: 400,
      line-height: 400,
    ));
    @include mq(sp) {
      font-size: mycalcSP(96);
      line-height: mycalcSP(96);
      left: 0;
    }
  }

}

.ttl_basic {
  width: 100%;
  position: relative;
  letter-spacing: 0;
  font-weight: 500;
  @include responsive((
    font-size: 36,
    line-height: 56,
    margin-top: 80,
    margin-bottom: 48,
    padding-top: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(22);
    line-height: mycalcSP(40);
    margin-top: mycalcSP(56);
    margin-bottom: mycalcSP(32);
    padding-top: mycalcSP(24);
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }


  &:before {
    width: 100%;
    background: $c_gray;
  }

  &:after {
    background: $c_red;
    @include responsive((
      width: 96,
    ));
    @include mq(sp) {
      width: mycalcSP(64);
    }
  }
}

.ttl_underline {
  width: 100%;
  position: relative;
  letter-spacing: 0;
  border-bottom: 1px solid $c_gray;
  @include responsive((
    font-size: 28,
    line-height: 44,
    padding-bottom: 32,
    margin-top: 80,
    margin-bottom: 48,
  ));
  @include mq(sp) {
    font-size: mycalcSP(24);
    line-height: mycalcSP(36);
    padding-bottom: mycalcSP(32);
    margin-top: mycalcSP(64);
    margin-bottom: mycalcSP(48);
  }
}

.ttl_frame {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  letter-spacing: 0;
  font-weight: 500;
  @include responsive((
    font-size: 32,
    line-height: 48,
    margin-top: 80,
    margin-bottom: 48,
  ));
  @include mq(sp) {
    font-size: mycalcSP(20);
    line-height: mycalcSP(32);
    margin-top: mycalcSP(64);
    margin-bottom: mycalcSP(32);
  }
}

.ttl_beta {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  letter-spacing: 0;
  font-weight: 500;
  @include responsive((
    font-size: 24,
    line-height: 40,
    margin-top: 64,
    margin-bottom: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(18);
    line-height: mycalcSP(28);
    margin-top: mycalcSP(64);
    margin-bottom: mycalcSP(32);
  }
}

.ttl_bar {
  position: relative;
  letter-spacing: 0;
  font-weight: 500;
  @include responsive((
    font-size: 20,
    line-height: 32,
    margin-top: 64,
    margin-bottom: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(16);
    line-height: mycalcSP(24);
    margin-top: mycalcSP(64);
    margin-bottom: mycalcSP(32);
  }
}

.ttl_bold {
  width: 100%;
  position: relative;
  letter-spacing: 0;
  font-weight: 500;
  @include responsive((
    font-size: 18,
    line-height: 24,
    margin-top: 64,
    margin-bottom: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(15);
    line-height: mycalcSP(20);
    margin-top: mycalcSP(64);
    margin-bottom: mycalcSP(32);
  }

}

.ttl_sec {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  margin: 0 auto;
  @include responsive((
    max-width: 1200,
    margin-bottom: 64,
  ));
  @include mq(sp) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto mycalcSP(24);
  }

  &_ja {
    letter-spacing: 0;
    font-weight: 500;
    font-family: $f_gothic;
    color: $c_red;
    @include responsive((
      font-size: 18,
      line-height: 32,
      margin-bottom: 28,
    ));
    @include mq(sp) {
      font-size: mycalcSP(14);
      line-height: mycalcSP(24);
      margin-bottom: mycalcSP(16);
    }
  }

  &_en {
    letter-spacing: 0;
    font-weight: 500;
    font-family: $f_en;
    color: $c_red;
    @include responsive((
      font-size: 18,
      line-height: 18,
      margin-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(14);
      line-height: mycalcSP(14);
      margin-bottom: mycalcSP(16);
    }
  }

  &_headline {
    letter-spacing: 0;
    font-weight: 400;
    font-family: $f_gothic;
    @include responsive((
      font-size: 48,
      line-height: 72,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(40);
    }
  }

  &_sub {

    &_ja {
      letter-spacing: 0;
      font-weight: 500;
      font-family: $f_gothic;
      color: $c_red;
      @include responsive((
        font-size: 16,
        line-height: 29,
        margin-bottom: 25,
      ));
      @include mq(sp) {
        font-size: mycalcSP(14);
        line-height: mycalcSP(24);
        margin-bottom: mycalcSP(16);
      }
    }

    &_en {
      letter-spacing: 0;
      font-weight: 500;
      font-family: $f_en;
      color: $c_red;
      @include responsive((
        font-size: 16,
        line-height: 16,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(14);
        line-height: mycalcSP(14);
        margin-bottom: mycalcSP(16);
      }
    }

    &_headline {
      letter-spacing: 0;
      font-weight: 400;
      font-family: $f_gothic;
      @include responsive((
        font-size: 40,
        line-height: 56,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(32);
      }
    }
  }

  &_wen {
    order: -1;
    font-family: $f_en;
    position: relative;
    color: $c_gray;
    white-space: nowrap;
    letter-spacing: 0;
    @include responsive((
      font-size: 160,
      line-height: 160,
      margin-bottom: 16,
    ));
    @include mq(sp) {
      font-size: mycalcSP(56);
      line-height: mycalcSP(56);
      margin-bottom: mycalcSP(16);
      padding-left: 0;
    }
  }
}

.content_headline {
  @extend .ttl_sec_headline;
  @include responsive((
    margin-bottom: 64,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(32);
  }
}

.ttl_ico,
.ttl_logo,
.ttl_reccomend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @include responsive((
    padding-left: 24,
    margin-bottom: 24,
  ));
  @include mq(sp) {
    padding-left: 0;
    margin-bottom: mycalcSP(24);
  }

  &_txt {
    font-weight: bold;
    letter-spacing: 0;
    font-family: $f_en;
    @include responsive((
      font-size: 24,
      line-height: 40,
    ));

    @include mq(sp) {
      width: mycalcSP(307);
      font-size: mycalcSP(18);
      line-height: mycalcSP(24);
    }
  }

  &_img {
    order: -1;
    @include responsive((
      margin-right: 8,
    ));
    @include mq(sp) {
      margin-right: mycalcSP(8);
    }

    svg {
      display: block;
      @include mq(sp) {
        width: mycalcSP(20);
        height: mycalcSP(20);
      }

      path {
        fill: $c_red;
        .studio & {
          fill: $c_brand;
        }
        .academy & {
          fill: $c_brand;
        }
      }
    }
  }
}

.ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  @include responsive((
    margin-bottom: 64,
    padding: 104 0,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(48);
    padding: mycalcSP(32 0);
  }

  &_ja {
    font-weight: 400;
    letter-spacing: .1em;
    position: relative;
    z-index: 1;
    @include responsive((
      font-size: 24,
      line-height: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(20);
      line-height: mycalcSP(32);
    }
  }

  &_en {
    order: -1;
    font-family: $f_en;
    font-weight: 400;
    position: absolute;
    letter-spacing: .1em;
    color: $c_red;
    top: 0;
    z-index: 0;
    white-space: nowrap;
    @include responsive((
      font-size: 240,
      line-height: 240,
    ));
    @include mq(sp) {
      font-size: mycalcSP(96);
      line-height: mycalcSP(96);
      letter-spacing: 0;
    }
  }
}

.ttl_frame,
.ttl_underline,
.ttl_beta,
.ttl_bar,
.ttl_bold,
.ttl_sec {
  &:first-child {
    margin-top: 0;
  }
}