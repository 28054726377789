.main_contents {
  width: 100%;
  max-width: none;
  position: relative;
  z-index: 0;

  @include responsive((
    padding-top: 320,
    padding-bottom: 320,
    margin-bottom: 360,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(160);
    padding-bottom: mycalcSP(160);
    margin-bottom: mycalcSP(136);
  }

  &_bg {
    position: absolute;
    left: 50%;
    z-index: -5;
    @include responsive((
      margin-left: -1200,
      bottom: -360,
    ));
    @include mq(sp) {
      margin-left: mycalcSP(-195);
      bottom: mycalcSP(-136);
    }
  }


  &_filter {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: -4;
    background: $c_gray_light;
    border-radius: 2350px 2350px / 1240px 1240px;
    box-shadow: inset 64px 64px 160px rgb(0 0 0 / 10%);
    //box-shadow: inset 64px 64px 160px rgb(0 0 0 / 25%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    @include responsive((
      width: 2400,
    ));

    @include mq(pc) {
      border-radius: #{mycalc(2350 2350)} / #{mycalc(1240 1240)};
      //box-shadow: inset #{mycalc(64 64 160)} rgb(0 0 0 / 25%);
    }

    @include mq(sp) {
      width: mycalcSP(1440);
      border-radius: #{mycalcSP(1400 1400)} / #{mycalcSP(1380 1380)};
      //box-shadow: inset #{mycalcSP(64 64 160)} rgb(0 0 0 / 25%);
    }
  }
}


.sec_next {
  width: 100%;
  border-top: 1px solid $c_gray;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  @include mq(sp) {
    padding: mycalcSP(0 24);
    justify-content: flex-start;
  }

  &_ttl {
    font-family: $f_en;
    @include responsive((
      font-size: 80,
      line-height: 80,
      padding-right: 186,
      padding-top: 120,
    ));
    @include mq(sp) {
      font-size: mycalcSP(36);
      line-height: mycalcSP(36);
      padding: mycalcSP(64 0 32);
    }
  }

  &_list {
    @include responsive((
      width: 1050,
    ));
    @include mq(sp) {
      width: 100%;
    }

    &_item {
      border-bottom: 1px solid $c_gray;

      &_link {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        @include responsive((
          padding: 80 216 80 0,
        ));
        @include mq(sp) {
          padding: mycalcSP(24 48 40 0);
        }
        &_jp {
          color: $c_red;
          @include responsive((
            font-size: 16,
            line-height: 24,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(20);
            margin-bottom: mycalcSP(8);
          }
        }
        &_en {
          font-family: $f_en;
          @include responsive((
            font-size: 40,
            line-height: 40,
          ));
          @include mq(sp) {
            font-size: mycalcSP(20);
            line-height: mycalcSP(20);
          }
        }

        .btn_circle {
          position: absolute;
          top: 50%;
          @include responsive((
            margin-top: -28,
            right: 80,
          ));
          @include mq(sp) {
            margin-top: mycalcSP(-20);
            right: 0;
          }
        }
      }
    }
  }
}