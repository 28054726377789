@use "sass:math";

$breakpointsMax: (
  'ss' : 'screen and (max-width: 320px)',
  'sp' : 'screen and (max-width: 768px)',
  'tb' : 'screen and (max-width: 1024px)',
  'pc' : 'screen and (max-width: #{$s_widthBase}px)',
  'wide' : 'screen and (max-width: #{$s_widthWide}px)',
  'full' : 'screen and (max-width: #{math.div($s_widthCompPC, $s_widthDesignMag)}px) and (min-width: #{$s_widthWide + 1}px)',
) !default;

$breakpointsMin: (
  'ss' : 'screen and (min-width: 321px)',
  'sp' : 'screen and (min-width: 769px)',
  'tb' : 'screen and (min-width: 1025px)',
  'pc' : 'screen and (min-width: #{$s_widthBase + 1}px)',
  'wide' : 'screen and (min-width: #{$s_widthWide + 1}px)',
  'full' : 'screen and (min-width: #{math.div($s_widthCompPC, $s_widthDesignMag)}px)',
) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpointsMax, $breakpoint)} {
    @content;
  }
}

@mixin minq($breakpoint: sp) {
  @media #{map-get($breakpointsMin, $breakpoint)} {
    @content;
  }
}

@mixin browser($browser) {
  @if ($browser == 'webkit') {
    /* chrome safari opera */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @content;
    }
  } @else if ($browser == 'chrome') {
    /* chrome opera */
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
      @content;
    }
  } @else if ($browser == 'safari') {
    /* safari */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      _::-webkit-full-page-media, _:future, :root .css-hack {
        @content;
      }
    }
  } @else if ($browser == 'firefox') {
    /* Firefox */
    @-moz-document url-prefix() {
      @content;
    }
  } @else if ($browser == 'ie') {
    /* IE10 & IE11 */
    _:-ms-input-placeholder, :root .css-hack {
      @content;
    }
  }
}


@mixin responsive($list) {
  $n: 0; // ループ用

  @each $prop, $val in $list {
    $v: 0; // 値
    $l: 0; // pc用変数初期化
    // 値がカンマ区切りの値だった場合（marginやpadding）
    @if type-of($val) == list {
      @each $v in $val {
        @if $n == 0 {
          @if $v == auto {
            $l: auto;
          } @else {
            $l: (math.div($v, $s_widthDesignMag) * 1px);
          }
        } @else {

          @if $v == auto {
            $l: ($l auto);
          } @else {

            @if $prop == "transform" or $prop == "transform-translate" {
              $l: ($l, (math.div($v, $s_widthDesignMag)) * 1px); // translate(10px, 20px)のフォーマット
            } @else {
              $l: ($l (math.div($v, $s_widthDesignMag)) * 1px);
            }
          }
        }
        $n: $n + 1;
      }

      // 値が配列でなく単一の場合
    } @else {
      @if $prop == "font-size" { // font-sizeはremに変換
        $l: (math.div(math.div($val, $s_widthDesignMag), 10)) * 1rem;
      } @else {
        $l: math.div($val, $s_widthDesignMag) * 1px;
      }
    }

    // 例外対応（特定の自作プロパティをCSSの正式な構文に変換）
    @if $prop == "filter-blur" {
      $prop: "filter";
      $l: blur($l);
    } @else if $prop == "transform" or $prop == "transform-translate" {
      $prop: "transform";
      $l: translate($l);
    } @else if $prop == "transform-x" {
      $prop: "transform";
      $l: translateX($l);
    } @else if $prop == "transform-y" {
      $prop: "transform";
      $l: translateY($l);
    } @else if $prop == "transform-z" {
      $prop: "transform";
      $l: translateZ($l);
    } @else if $prop == "transform-perspective" {
      $prop: "transform";
      $l: perspective($l);
    }

    // 出力
    #{$prop}: #{$l};

    $n: 0; // 初期化
  }

  @include minq(sp) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalcFull($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              @if $prop == "transform" or $prop == "transform-translate" {
                $m: ($m, mycalcFull($v));; // translate(10px, 20px)のフォーマット
              } @else {
                $m: ($m mycalcFull($v));
              }
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {

        $m: mycalcFull($val);

      }

      // 例外対応（特定の自作プロパティをCSSの正式な構文に変換）
      @if $prop == "filter-blur" {
        $prop: "filter";
        $m: blur($m);
      } @else if $prop == "transform" or $prop == "transform-translate" {
        $prop: "transform";
        $m: translate($m);
      } @else if $prop == "transform-x" {
        $prop: "transform";
        $m: translateX($m);
      } @else if $prop == "transform-y" {
        $prop: "transform";
        $m: translateY($m);
      } @else if $prop == "transform-z" {
        $prop: "transform";
        $m: translateZ($m);
      } @else if $prop == "transform-perspective" {
        $prop: "transform";
        $m: perspective($m);
      }

      // 出力
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }

}



/*
 * デザインデータ（2倍サイズ）の数値を入れることで、
 * 基準値以下を縮小表示する関数。
 */
@mixin responsive_old($list) {
  $n: 0; // ループ用

  @each $prop, $val in $list {
    $v: 0; // 値
    $l: 0; // pc用変数初期化
    // 値がカンマ区切りの値だった場合（marginやpadding）
    @if type-of($val) == list {
      @each $v in $val {
        @if $n == 0 {
          @if $v == auto {
            $l: auto;
          } @else {
            $l: (math.div($v, $s_widthDesignMag) * 1px);
          }
        } @else {
          @if $v == auto {
            $l: ($l auto);
          } @else {
            $l: ($l (math.div($v, $s_widthDesignMag)) * 1px);
          }
        }
        $n: $n + 1;
      }
      // 値が配列でなく単一の場合
    } @else {
      @if $prop == "font-size" {
        $l: (math.div(math.div($val, $s_widthDesignMag), 10)) * 1rem;
      } @else {
        $l: math.div($val, $s_widthDesignMag) * 1px;
      }
    }
    #{$prop}: #{$l};
    $n: 0; // 初期化
  }

  @include mq(pc) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalc($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              $m: ($m mycalc($v));
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {
        $m: mycalc($val);
      }
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }

}


/*
 * フルスクリーンコンテンツを推奨最小サイズまで縮小表示し、
 * 推奨最小サイズを下回った際に、元のサイズに戻す処理。
 */
@mixin responsiveFull($list) {
  $n: 0; // ループ用

  @each $prop, $val in $list {
    $v: 0; // 値
    $l: 0; //pc用変数初期化
    // 値がカンマ区切りの値だった場合（marginやpadding）
    @if type-of($val) == list {
      @each $v in $val {
        @if $n == 0 {
          @if $v == auto {
            $l: auto;
          } @else {
            $l: (math.div($v, $s_widthDesignMag));
          }
        } @else {
          @if $v == auto {
            $l: ($l auto);
          } @else {
            $l: ($l (math.div($v, $s_widthDesignMag)));
          }
        }
        $n: $n + 1;
      }
      // 値が配列でなく単一の場合
    } @else {
      $l: math.div($v, $s_widthDesignMag);
    }
    #{$prop}: #{$l};
    $n: 0; // 初期化
  }

  @include mq(full) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalcFull($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              $m: ($m mycalcFull($v));
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {
        $m: mycalcFull($val);
      }
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }

  @include mq(pc) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalc($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              $m: ($m mycalc($v));
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {
        $m: mycalc($val);
      }
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }
}


/*
 * デザインサイズ以上に広がった際に、
 * 画面サイズに応じて拡大縮小させる処理。
 */
@mixin responsiveScale($list) {
  $n: 0; // ループ用

  @include minq(full) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalcFull($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              $m: ($m mycalcFull($v));
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {
        $m: mycalcFull($val);
      }
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }

  @each $prop, $val in $list {
    $v: 0; // 値
    $l: 0; //pc用変数初期化
    // 値がカンマ区切りの値だった場合（marginやpadding）
    @if type-of($val) == list {
      @each $v in $val {
        @if $n == 0 {
          @if $v == auto {
            $l: auto;
          } @else {
            $l: (math.div($v, $s_widthDesignMag));
          }
        } @else {
          @if $v == auto {
            $l: ($l auto);
          } @else {
            $l: ($l (math.div($v, $s_widthDesignMag)));
          }
        }
        $n: $n + 1;
      }
      // 値が配列でなく単一の場合
    } @else {
      $l: math.div($v, $s_widthDesignMag);
    }
    #{$prop}: #{$l};
    $n: 0; // 初期化
  }

  @include mq(pc) {
    $n: 0; // 初期化
    @each $prop, $val in $list {
      $v: 0; // 値
      $m: 0; // sp用変数初期化
      // 値がカンマ区切りの値だった場合（marginやpadding）
      @if type-of($val) == list {
        @each $v in $val {
          @if $n == 0 {
            @if $v == auto {
              $m: auto;
            } @else {
              $m: mycalc($v);
            }
          } @else {
            @if $v == auto {
              $m: ($m auto);
            } @else {
              $m: ($m mycalc($v));
            }
          }
          $n: $n + 1;
        }
        // 値が配列でなく単一の場合
      } @else {
        $m: mycalc($val);
      }
      #{$prop}: #{$m};
      $n: 0; // 初期化
    }
  }
}


/*
 * フルスクリーンコンテンツを
 * background: cover; のように振る舞わせる処理。
 * imgを内包する親要素に適応すること。
 */
@mixin responsiveCover($height, $width: math.div($s_widthCompPC, $s_widthDesignMag)) {
  position: relative;
  height: mycalcFull($height);

  @media screen and (max-width: $width) {
    height: math.div($height, $s_widthDesignMag);
  }
  @include mq(pc) {
    height: mycalc($height);
  }

  img {
    max-width: none;
    width: 100%;
    height: auto;

    @media screen and (max-width: $width) {
      width: auto;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -#{math.div($width, $s_widthDesignMag)}px;
    }

    @include mq(pc) {
      margin-left: mycalc(-$width*.5);
      width: auto;
      height: 100%;
    }
  }
}


@function mycalc($val) {
  $value: '';
  @each $v in $val {
    @if ($value == '') {
      $value: math.div(100vw, $s_widthWide) * math.div($v, $s_widthDesignMag);
    } @else {
      $value: $value (math.div(100vw, $s_widthWide) * math.div($v, $s_widthDesignMag));
    }
  }
  @return $value;
}


@function mycalcWide($val) {
  $value: '';
  @each $v in $val {
    @if ($value == '') {
      $value: math.div(100vw, $s_widthWide) * math.div($v, $s_widthDesignMag);
    } @else {
      $value: $value (math.div(100vw, $s_widthWide) * math.div($v, $s_widthDesignMag));
    }
  }
  @return $value;
}


@function mycalcFull($val) {
  $value: '';
  @each $v in $val {
    @if ($value == '') {
      $value: math.div(100vw, $s_widthCompPC) * math.div($v, $s_widthDesignMag);
    } @else {
      $value: $value (math.div(100vw, $s_widthCompPC) * math.div($v, $s_widthDesignMag));
    }
  }
  @return $value;
}


@function mycalcSP($val) {
  $value: '';
  @each $v in $val {
    @if ($value == '') {
      @if ($v == auto) {
        $value: auto;
      } @else {
        $value: math.div(100vw, $s_widthCompSP) * math.div($v, $s_widthDesignMag);
      }
    } @else {
      @if ($v == auto) {
        $value: $value auto;
      } @else {
        $value: $value (math.div(100vw, $s_widthCompSP) * math.div($v, $s_widthDesignMag));
      }
    }
  }
  @return $value;
}


@mixin flex($dir, $jc, $align_i, $align_c, $wrap) {
  display: flex;

  @if ($dir != none) {
    flex-direction: $dir;
  }
  @if ($jc != none) {
    justify-content: $jc;
  }
  @if ($align_i != none) {
    align-items: $align_i;
  }
  @if ($align_c != none) {
    align-content: $align_c;
  }
  @if ($wrap != none) {
    flex-wrap: $wrap;
  }
}

@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }

  // IE
  &:-ms-input-placeholder {
    color: $color;
  }

  // Edge
  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin scrollbar($color, $width:5px) {
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-thumb {
    background: $color;
    border-radius: 3px;
    box-shadow: none;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 3px;
    //background: #e8e8e8;
    //box-shadow: inset 0 0 2px #777;
  }
}

@mixin gradientBg($startColor, $endColor) {
  background: $startColor;
  background: -moz-linear-gradient(left, $startColor 0%, $endColor 80%, $endColor 100%);
  background: -webkit-linear-gradient(left, $startColor 0%, $endColor 80%, $endColor 100%);
  background: linear-gradient(to right, $startColor 0%, $endColor 80%, $endColor 100%);
}

@mixin textGradient($startColor, $endColor, $child: false, $easing: $p_over, $delay: 0s) {

  //@include minq(sp) {
    @if $child {
      #{$child} {
        animation: TEXT-MASK-OVER $easing cubic-bezier(.23, 1, .32, 1) 0s forwards;
        background-color: transparent;
        background-image: linear-gradient(to right, $startColor 50%, $endColor 0);
        background-position: 200% 0;
        background-size: 200% 100%;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
      &:hover #{$child} {
        animation: TEXT-MASK $easing cubic-bezier(0.23, 1, 0.32, 1) $delay forwards;
      }
    } @else {
      animation: TEXT-MASK-OVER $easing cubic-bezier(.23, 1, .32, 1) 0s forwards;
      background-color: transparent;
      background-image: linear-gradient(to right, $startColor 50%, $endColor 0);
      background-position: 200% 0;
      background-size: 200% 100%;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

      &:hover {
        animation: TEXT-MASK $easing cubic-bezier(0.23, 1, 0.32, 1) $delay forwards;
      }
    }
  //}
}

@mixin overflowText($line, $lineheight, $r: 255, $g: 255 , $b: 255) {
  position: relative;
  line-height: $lineheight;
  max-height: calc(#{$lineheight}em * #{$line});
  overflow: hidden;
  background: rgba($r, $g, $b, 1);

  &_txt {
    font-weight: inherit;
    margin-right: 1em;
  }

  &:before {
    content: "…";
    bottom: 0;
    right: 0;
    position: absolute;
    color: rgb((255-$r), (255-$g), (255-$b));
    background: linear-gradient(to right, rgba($r, $g, $b, 0) 0%, rgba($r, $g, $b, 1) 30%);
    padding-left: 1em;
    transition: $t_hv;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    //margin-left: -1em;
    position: absolute;
    background: rgba($r, $g, $b, 1);
  }
}

@mixin imgBlur($range: 32, $spread: 90) {
  z-index: -1;
  position: absolute;
  opacity: .5;
  top: #{$range}px;
  left: #{$range}px;
  filter: blur(#{$spread*.5}px);
  @include mq(pc) {
    top: #{mycalc($range*2)};
    left: #{mycalc($range*2)};
    filter: blur(#{mycalc($spread)});
  }
  @include mq(sp) {
    top: #{mycalcSP($range*.5)};
    left: #{mycalcSP($range*.5)};
    filter: blur(#{mycalcSP($spread)});
  }
}


@mixin targetBlank() {
  color: $c_red;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &[target="_blank"] {
    position: relative;
    padding-right: 1.5em;

    &:after {
      content: "→";
      transform: rotate(-45deg) translate(0, -50%);
      transform-origin: center center;
      position: absolute;
      color: inherit;
      top: 50%;
      right: 0;
      width: 1em;
      height: 1em;
      font-size: 1em;
      line-height: 1em;
    }
  }
}
