/*
 * kv.scss
 *
 * キービジュアル用コンポーネント
 * 主にトップページで利用。
 */
@use "sass:math";

.kv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  color: $c_white;
  background: $c_hyacinth;
  z-index: 0;
  overflow: hidden;
  height: 100vh;

  @include responsive((
    min-height: 1080,
  ));

  @include mq(sp) {
    min-height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    background: $c_blue;
    opacity: .4;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}


.kv_headline {
  width: calc(100% - 160px);
  position: absolute;
  z-index: 2;
  @include responsive((
    left: 120,
    bottom: 38,
  ));
  @include mq(pc) {
    width: calc(100% - #{mycalc(160)});
  }
  @include mq(sp) {
    width: calc(100% - #{mycalcSP(48)});
    left: mycalcSP(24);
    bottom: mycalcSP(56);
  }

  &_ttl {
    color: $c_white;
    font-family: $f_en;
    @include responsive((
      font-size: 160,
      line-height: 160,
    ));
    @include mq(sp) {
      font-size: mycalcSP(48);
      line-height: mycalcSP(48);
    }
  }

  &_desc {
    color: $c_white;
    clip-path: inset(0 100% 0 0);
    display: inline-block;
    position: relative;
    transition: clip-path 1s $e_curtain 2.2s;
    @include responsive((
      font-size: 26,
      line-height: 42,
      margin-left: 16,
      top: -32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(14);
      line-height: mycalcSP(24);
      margin-left: 0;
      top: mycalcSP(-45);
      transition: clip-path 1s $e_curtain 2.6s;
    }

    .loaded & {
      clip-path: inset(0 0 0 0);
    }
  }
}


.kv_figure {
  position: absolute;
  z-index: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 4.2s $e_curtain 0s,
  transform 4s $e_curtain .2s;

  .loaded & {
    opacity: 1;
    transform: scale(1.1);
  }

  &_img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}


._line {
  position: relative;

  // clip-path: inset(100% 0 0 0);
  // transition: clip-path 1s $e_syu 1.4s;

  /*
  .loaded & {
    clip-path: inset(0 0 0 0);
  }
  */
  @include mq(sp) {
    display: block;
  }

  ._txt {
    @include responsive((
      padding-bottom: 24,
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(24);
    }
  }

  &:nth-child(1) {
    ._txt {
      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          transition-delay: .03s * $i + 1.4s;
        }
      }
    }
  }

  &:nth-child(2) {
    @include mq(sp) {
      top: mycalcSP(-25);
    }

    ._txt {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: .03s * $i + 1.5s;
        }
      }
    }
  }

  &:last-child {
    @include responsive((
      top: -20,
    ));
    @include mq(sp) {
      top: mycalcSP(-48);
    }


    ._txt {
      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          transition-delay: .03s * $i + 1.5s;
          @include mq(sp) {
            transition-delay: .03s * $i + 1.9s;
          }
        }
      }
    }
  }
}


/*
.__l, .__r, .__b, .__t {
  display: inline-block;
  transition:
    clip-path 1.4s $e_curtain 1.4s,
    transform 1.4s $e_curtain 1.4s;

  .loaded & {
    clip-path: inset(0 0 0 0);
    transform: translate3d(0, 0, 0);
  }
}

.__l {
  clip-path: inset(0 0 0 100%);
  transform: translate3d(-10%, 0, 0);
}

.__r {
  clip-path: inset(0 100% 0 0);
  transform: translate3d(10%, 0, 0);
}

.__b {
  clip-path: inset(0 0 100% 0);
  transform: translate3d(0, 10%, 0);
}

.__t {
  clip-path: inset(100% 0 0 0);
  transform: translate3d(0, -10%, 0);
}
*/

