/*
 * btn.scss
 *
 * ボタン用コンポーネント
 * 定義する際は、接頭辞としてbtn_を付けること。
 */


.btn {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  flex-shrink: 0;

  &_link {
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    color: $c_text;
    letter-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $c_gray;
    @include responsive((
      padding: 20 56 20 62,
      border-radius: 28,
    ));
    @include mq(sp) {
      padding: mycalcSP(20 56 20 62);
      border-radius: mycalcSP(28);
    }

    &_txt {
      color: inherit;
      position: relative;
      z-index: 1;
      font-weight: bold;
      transition: color .4s $e_curtain .1s;
      @include responsive((
        font-size: 16,
        line-height: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(14);
        line-height: mycalcSP(16);
      }
    }

    &:hover &_txt {
      color: $c_white;
    }

    .white & {
      color: $c_white;
      border-color: $c_white;

      &:before {
        background: $c_white;
      }
    }
    .white &:hover &_txt {
      color: $c_text;
    }

    .white_bg & {
      color: $c_text;
      background: $c_white;
      border-color: $c_white;

      &:before {
        background: $c_text;
      }
    }
  }

  &:before,
  &_link:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 50%;
    background: $c_black;
    transition: transform .6s $e_curtain, opacity .6s $e_curtain;
    pointer-events: none;
    @include responsive((
      width: 6,
      height: 6,
      margin-top: -3,
      left: 24,
    ));
    @include mq(sp) {
      width: mycalcSP(6);
      height: mycalcSP(6);
      margin-top: mycalcSP(-3);
      left: mycalcSP(24);
    }
  }

  &:before {
    transition: transform .4s $e_curtain, opacity .4s $e_curtain;
  }

  &.white {
    &:before {
      background: $c_white;
    }
  }

  &:hover:before {
    transform: scale(20);
  }
  &_link:hover:before {
    transform: scale(80);
  }
  &_link:hover:before {
    transform: scale(80);

    .short & {
      transform: scale(60);
    }
    .medium & {
      transform: scale(82);
    }
    .long & {
      transform: scale(108);
    }
  }

  &:hover:before {
    opacity: 0;
  }
}


.btn_round {
  position: relative;
  margin: 0 auto;
  @include responsive((
    width: 320,
    height: 72,
  ));
  @include mq(sp) {
    width: mycalcSP(320);
    height: mycalcSP(56);
    overflow: hidden;
  }

  // 外円
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    border-radius: 50%;
    box-sizing: border-box;
    background: $c_white;
    opacity: .2;
    transform: scale(0);
    @include responsive((
      width: 320,
      height: 320,
      margin-top: -160,
      margin-left: -160,
    ));
    @include mq(sp) {
      content: none;
    }
  }

  @include minq(sp) {
    &:hover:before {
      opacity: 0;
      transform: scale(1);
      transition: transform $t_btn, opacity $t_btn;
    }
  }

  &_link {
    width: 100%;
    height: 100%;
    background: $c_red;
    color: $c_white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: background-color $t_btn;
    @include responsive((
      padding: 16 64,
      border-radius: 36,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      border-radius: mycalcSP(36);
      flex-flow: column;
      padding: mycalcSP(16);
    }

    &_txt {
      font-weight: bold;
      position: relative;
      z-index: 0;
      color: $c_white;
      @include responsive((
        font-size: 16,
        line-height: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(24);
      }
    }

    // 内円
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      border-radius: 50%;
      box-sizing: border-box;
      opacity: .2;
      transform: scale(0);
      @include responsive((
        width: 320,
        height: 320,
        margin-top: -160,
        margin-left: -160,
      ));
      @include mq(sp) {
        content: none;
      }
    }

    @include minq(sp) {
      &:hover:before {
        transition: transform $t_btn;
        transform: scale(1);
      }

      &:hover,
      &:before {
        background-color: $c_red_light;
      }
    }


    .navy & {
      background: $c_navy;

      &:hover,
      &:before {
        background-color: $c_navy_light;
      }
    }
  }
}


.btn_circle {
  position: relative;
  @include responsive((
    width: 56,
    height: 56,
  ));
  @include mq(sp) {
    width: mycalcSP(40);
    height: mycalcSP(40);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: $c_black;
    @include responsive((
      width: 4,
      height: 4,
      margin-top: -2,
      margin-left: -2,
    ));
    @include mq(sp) {
      width: mycalcSP(4);
      height: mycalcSP(4);
      margin-top: mycalcSP(-2);
      margin-left: mycalcSP(-2);
    }
  }

  &_wrap {
    &:hover {
      .ico_circle {
        transform: scale(1.066);

        circle {
          opacity: 1;
          stroke: $c_gray_dark;
          stroke-dashoffset: 760;
          stroke-width: 1px;
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(1520);
          }
        }
      }

      .btn_circle:before {
        transform: scale(20);
        opacity: 0;
        transition: transform .5s $e_curtain, opacity .5s $e_curtain;
      }
    }
  }

  .ico_circle {
    width: 100%;
    height: 100%;
    transition: transform $t_btn;

    circle {
      transition: opacity $t_btn, stroke-dashoffset $t_btn, stroke $t_btn, stroke-width $t_btn;
      stroke-dashoffset: 0;
      stroke-dasharray: 380;
      opacity: 1;
      stroke: $c_gray;
      stroke-width: 2px;
    }
  }
}


.btn_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}


.mail {
  background: $c_white;
  @include responsive((
    width: 400,
    height: 112,
  ));
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(112);
  }

  &_link {
    @include textGradient($c_blue, $c_white, '.btn_link_txt', $p_slide, .1s);
    color: $c_blue;

    .ico_mail {
      @include responsive((
        width: 48,
        height: 48,
        margin-right: 8,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin-right: mycalcSP(8);
      }

      path{
        fill: $c_blue;
        transition: fill $t_slide .01s;
      }
    }

    &:hover .ico_mail path {
      fill: $c_white;
    }

    &_txt {
      @include responsive((
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
      }

      &_way {
        font-weight: inherit;
      }
    }


    &:after {
      background: $c_blue;

      .studio & {
        background: $c_brand;
      }

      .academy & {
        background: $c_brand;
      }
    }
  }
}


.sns {
  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_item {
      @include responsive((
        width: 40,
        height: 40,
        margin-right: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin-right: mycalcSP(32);
      }

      &:last-child {
        margin-right: 0;
      }

      &_link {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}