.about_service {
  position: relative;

  .sec_ttl_en {
    left: 50%;
    @include responsive((
      margin-left: -600,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  &_desc {
    width: 100%;
    box-sizing: border-box;
    @include responsive((
      margin-bottom: 80,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(48);
    }

    p {
      text-align: center;
      @include responsive((
        font-size: 18,
        line-height: 40,
      ));
      @include mq(sp) {
        text-align: left;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_figure {
    overflow: hidden;
    margin: 0 auto;
    @include responsive((
      width: 846,
      height: 476,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      width: mycalcSP(390);
      height: mycalcSP(218);
      margin-bottom: mycalcSP(64);
      margin-left: mycalcSP(-24);
    }

    &_video,
    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include mq(sp) {
      border-top: 1px solid $c_gray;
      padding-top: mycalcSP(48);
    }

    &_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      @include responsive((
        margin-bottom: 160,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
        position: relative;
        padding-top: mycalcSP(64);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &_info {
        border-top: 1px solid $c_gray;
        border-bottom: 1px solid $c_gray;
        @include responsive((
          width: 560,
          padding: 48 0 96,
        ));
        @include mq(sp) {
          border-top: 0;
          width: 100%;
          padding: mycalcSP(40 0 64);
        }

        .btn {
          @include responsive((
            margin-top: 48,
          ));
          @include mq(sp) {
            margin-top: mycalcSP(40);
          }
        }
      }

      &_ttl {
        position: relative;
        @include responsive((
          font-size: 32,
          line-height: 40,
          margin-bottom: 24,
          padding-left: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(32);
          margin-bottom: mycalcSP(32);
          padding-left: mycalcSP(24);
          position: absolute;
          top: 0;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          background: $c_red;
          border-radius: 50%;
          @include responsive((
            width: 8,
            height: 8,
            top: 18,
          ));
          @include mq(sp) {
            width: mycalcSP(8);
            height: mycalcSP(8);
            top: mycalcSP(12);
          }
        }
      }
      
      &_figure {
        overflow: hidden;
        background: $c_hyacinth_light;
        @include responsive((
          width: 480,
          height: 640,
        ));
        @include mq(sp) {
          width: mycalcSP(288);
          height: mycalcSP(384);
          margin: 0 auto;
          order: -1;
        }
        &_img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &:nth-child(even) {
        .about_service_list_item_figure {
          order: -1;
        }
      }
    }
  }

}


.about_features {
  position: relative;

  .sec_inner {
    text-align: center;
    @include mq(sp) {
      margin-top: mycalcSP(48);
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      padding: 60 0 40,
    ));
    @include mq(sp) {
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      padding: mycalcSP(0);
    }

    &_item {
      @include responsive((
        width: 254,
        margin-right: 30,
      ));
      @include mq(sp) {
        width: mycalcSP(342);
        margin: 0 auto;
        margin-bottom: mycalcSP(64);
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
        @include mq(sp) {
          margin-right: auto;
        }
      }

      &_head {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        position: relative;
        z-index: 0;
        @include responsive((
          height: 314,
          margin-bottom: 38,
        ));
        @include mq(sp) {
          height: mycalcSP(256);
          margin-bottom: mycalcSP(48);
        }

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
        }

        &:before {
          opacity: .7;
          background: $c_white;
          border: 1px solid $c_gray;
          z-index: -1;
          @include responsive((
            width: 314,
            height: 314,
            margin-left: -157,
            margin-top: -157,
          ));
          @include mq(sp) {
            width: mycalcSP(256);
            height: mycalcSP(256);
            margin-left: mycalcSP(-128);
            margin-top:  mycalcSP(-128);
          }
        }

        &:after {
          background: $c_black;
          opacity: .05;
          z-index: -2;
          transform: scale(0);
          transition: transform $t_pop;
          @include responsive((
            width: 344,
            height: 344,
            margin-left: -172,
            margin-top: -172,
          ));
          @include mq(sp) {
            width: mycalcSP(288);
            height: mycalcSP(288);
            margin-left: mycalcSP(-144);
            margin-top: mycalcSP(-144);
          }

          .about_features_list_item:nth-child(1) & {
            transition-delay: .2s;
          }

          .about_features_list_item:nth-child(2) & {
            transition-delay: .4s;
          }

          .about_features_list_item:nth-child(3) & {
            transition-delay: .6s;
          }

          .is-active & {
            transform: scale(1);
          }
        }

        .num {
          color: $c_red;
          font-family: $f_en;
          @include responsive((
            font-size: 20,
            line-height: 20,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(20);
            line-height: mycalcSP(20);
            margin-bottom: mycalcSP(24);
          }
        }

        .name {
          @include responsive((
            font-size: 24,
            line-height: 48,
            margin-bottom: 0,
          ));
          @include mq(sp) {
            font-size: mycalcSP(20);
            line-height: mycalcSP(24);
            margin-bottom: mycalcSP(28);
          }
        }
      }

      .txt {
        text-align: left;
        @include responsive((
          font-size: 16,
          line-height: 36,
        ));
        @include mq(sp) {
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
        }
      }
    }
  }

  .btn {
    @include responsive((
      margin-top: 45,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(40);
    }
  }

}


.about_data {
  position: relative;

  .sec_ttl_en {
    width: 100%;
    text-align: center;
    left: 0;
    @include responsive((
      margin-left: 0,
    ));
    @include mq(sp) {
      left: 0;
      margin-left: 0;
    }
  }

  &_visual {
    width: 100%;
    overflow: hidden;
    @include responsive((
      margin-top: 120,
      margin-bottom: 120,
    ));
    @include mq(sp) {
      margin-top: 0;
      margin-bottom: mycalcSP(48);
    }

    &_figure {
      margin: 0 auto;
      mask-image: url("../images/common/bg/mask.png");
      mask-repeat: no-repeat;
      mask-size: 83.3% 100%;
      mask-position: 50% 100%;
      @include responsive((
        width: 1920,
        height: 800,
      ));
      @include mq(sp) {
        width: mycalcSP(390);
        height: mycalcSP(170);
        mask-size: 87.6% 100%; // window bottom時のサイズ
      }

      &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &_ttl {
    width: 100%;
    text-align: center;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      text-align: left;
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(48);
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include mq(sp) {
      flex-flow: column;
      align-items: center;
    }

    &_item {
      background: $c_white;
      box-sizing: border-box;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      @include responsive((
        width: 560,
        height: 560,
        margin-bottom: 80,
        padding-bottom: 58,
      ));
      @include mq(sp) {
        width: mycalcSP(342);
        height: mycalcSP(342);
        margin: 0 auto mycalcSP(32);
        padding-bottom: mycalcSP(40);
      }
      &:nth-child(odd) {
        @include responsive((
          margin-right: 80,
        ));
        @include mq(sp) {
          margin-right: auto;
        }
      }
      @include minq(sp) {
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }

      &_ttl {
        width: 100%;
        font-weight: 500;
        text-align: center;
        @include responsive((
          font-size: 24,
          line-height: 48,
        ));
        @include mq(sp) {
          font-size: mycalcSP(18);
          line-height: mycalcSP(32);
        }

        strong {
          color: $c_red;
          @include responsive((
            font-size: 36,
            line-height: 48,
            padding: 0 4,
          ));
          @include mq(sp) {
            font-size: mycalcSP(32);
            line-height: mycalcSP(32);
            padding: mycalcSP(0 4);
          }
        }
      }

      &_pic {
        order: -1;

        &_svg {
          display: block;

          .about_data_history & {
            height: auto;
            @include responsive((
              width: 271,
              margin-left: 54,
              margin-bottom: 48,
            ));
            @include mq(sp) {
              width: mycalcSP(162);
              margin-left: mycalcSP(48);
              margin-bottom: mycalcSP(32);
            }
          }

          .about_data_bearing & {
            height: auto;
            @include responsive((
              width: 360,
              margin-bottom: 38,
            ));
            @include mq(sp) {
              width: mycalcSP(214);
              margin-bottom: mycalcSP(-11);
            }
          }

          .about_data_china & {
            height: auto;
            @include responsive((
              width: 462,
              margin-bottom: 46,
            ));
            @include mq(sp) {
              width: mycalcSP(280);
              margin-bottom: mycalcSP(32);
            }
          }

          .about_data_world & {
            height: auto;
            @include responsive((
              width: 514,
              margin-bottom: 50,
            ));
            @include mq(sp) {
              width: mycalcSP(294);
              margin-bottom: mycalcSP(62);
            }
          }
        }
        
        &_num {
          border-radius: 50%;
          color: $c_white;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          @include responsive((
            font-size: 16,
            line-height: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(15);
            line-height: mycalcSP(15);
          }

          .line {
            position: relative;
            z-index: 1;
          }

          .num {
            font-family: $f_en;
            @include responsive((
              font-size: 32,
              line-height: 32,
              margin-right: 4,
            ));
            @include mq(sp) {
              font-size: mycalcSP(24);
              line-height: mycalcSP(24);
              margin-right: mycalcSP(2);
            }
          }

          .circle {
            border-radius: 50%;
            background: $c_red;
            opacity: .7;
            position: absolute;
            z-index: 0;
          }

          .circle_bg {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $c_red;
            opacity: 1;
            position: absolute;
            z-index: 0;
          }

          .about_data_history & {
            @include responsive((
              width: 136,
              height: 136,
              top: 116,
              left: 108,
            ));
            @include mq(sp) {
              width: mycalcSP(120);
              height: mycalcSP(120);
              top: mycalcSP(56);
              left: mycalcSP(48);
            }

            .circle {
              @include responsive((
                width: 160,
                height: 160,
                top: -12,
                left: -12,
              ));
              @include mq(sp) {
                width: mycalcSP(136);
                height: mycalcSP(136);
                top: mycalcSP(-8);
                left: mycalcSP(-8);
              }
            }
          }

          .about_data_bearing & {
            left: 50%;
            @include responsive((
              width: 120,
              height: 120,
              top: 168,
              margin-left: -60,
            ));
            @include mq(sp) {
              width: mycalcSP(88);
              height: mycalcSP(88);
              top: mycalcSP(92);
              margin-left: mycalcSP(-44);
            }
            .circle {
              @include responsive((
                width: 144,
                height: 144,
                top: -12,
                left: -12,
              ));
              @include mq(sp) {
                width: mycalcSP(104);
                height: mycalcSP(104);
                top: mycalcSP(-8);
                left: mycalcSP(-8);
              }
            }
          }

          .about_data_china & {
            @include responsive((
              width: 136,
              height: 136,
              top: 116,
              right: 76,
            ));
            @include mq(sp) {
              width: mycalcSP(120);
              height: mycalcSP(120);
              top: mycalcSP(56);
              right: mycalcSP(48);
            }
            .circle {
              @include responsive((
                width: 160,
                height: 160,
                top: -12,
                left: -12,
              ));
              @include mq(sp) {
                width: mycalcSP(136);
                height: mycalcSP(136);
                top: mycalcSP(-8);
                left: mycalcSP(-8);
              }
            }
          }

          .about_data_world & {
            @include responsive((
              width: 160,
              height: 160,
              top: 95,
              left: 140,
            ));
            @include mq(sp) {
              width: mycalcSP(120);
              height: mycalcSP(120);
              top: mycalcSP(110);
              left: mycalcSP(124);
            }

            .circle {
              @include responsive((
                width: 184,
                height: 184,
                top: -12,
                left: -12,
              ));
              @include mq(sp) {
                width: mycalcSP(136);
                height: mycalcSP(136);
                top: mycalcSP(-8);
                left: mycalcSP(-8);
              }
            }
          }
        }
      }
    }
  }

}
