/* =============================
 * Size
 */

$s_widthCompPC: 1920; // PC DesignCompData WidthSizePX
$s_widthCompSP: 390;  // SP DesignCompData WidthSizePX
$s_widthDesignMag: 1; // デザインデータの倍率（2倍の場合は、「2」）
$s_widthBase: 1280;   // デザインデータのPC基準値（最小固定値）
$s_widthWide: 1600;   // 推奨最小閲覧サイズ（PCのサイドメニューなども考慮すること）


/* =============================
 * Color
 */

$c_black: #0C0E13;
$c_white: #ffffff;

$c_red: #E60012;
$c_red_opacity: rgba(230, 0, 18, 0.3);
$c_red_light: #F84A59;
$c_blue: #141C46;
$c_navy: #2C3943;
$c_navy_light: #394B58;

$c_gray: #E4E4E9;
$c_gray_light: #F9F9FE;
$c_gray_dark: #3D3F48;

$c_hyacinth: #7A8192;
$c_hyacinth_opacity: rgba(122, 129, 146, .7);
$c_hyacinth_light: rgba(122, 129, 146, .3);
$c_text: #303030;



/*
$c_gradient_emerald: linear-gradient(to right, #39fad7 0%, #39fad7 50%, #44ea76 60%, #44ea76 100%);
$c_gradient_emerald_smooth: linear-gradient(to right, #39fad7 0%, #44ea76 100%);
$c_gradient_blue: linear-gradient(to right, #0061FC 0%, #0069FF 80%, #0069FF 100%);
*/

// role
$c_brand: $c_red;
$c_bg: $c_gray_light;
$c_bg_footer: $c_gray_dark;
$c_text: $c_black;
$c_border: $c_gray;
$c_btn: $c_white;
$c_btn_hover: $c_navy_light;
$c_btn_contact_hover: $c_red_light;
$c_placeholder: #BCBCBC;

$c_heat: #DA0012;
$c_insulation: #7A8192;
$c_vacuum: #006FBE;
$c_magnetic: #B54C00;
$c_corrosion: #0A7E00;
$c_rotation: #4D5AEE;


/* =============================
 * Font
 */
@import url('https://fonts.googleapis.com/css2?family=Michroma&family=Noto+Sans+JP:wght@400;500;700&display=swap');

$f_gothic: 'Noto Sans JP', "游ゴシック体", "Yu Gothic", "游ゴシック", YuGothic, Verdana, "メイリオ", Meiryo, "M+ 1p", sans-serif;
$f_mincho: "Yu Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN", serif, "Sawarabi Mincho"; // Android 明朝体非対応対策
$f_offline: system-ui,"Hiragino Sans", "游ゴシック体", "Yu Gothic", "游ゴシック", YuGothic, Verdana, "メイリオ", Meiryo, "M+ 1p", sans-serif;
$f_en: 'Michroma', $f_gothic;
$f_ie10: Verdana, Meiryo, sans-serif;
//$f_en_sans: 'Montserrat', $f_gothic;


/* =============================
 * Speed
 */

$p_hum    : .8s;
$p_hv     : .6s;
$p_slide  : .3s;
$p_scroll : .6s;
$p_over   : .4s;


/* =============================
 * Easing
 */
$e_curtain     : cubic-bezier(.25, .46, .45, .94);
$e_curtain_rev : cubic-bezier(.94, .45, .46, .25);
$e_syu         : cubic-bezier(.645, .045, .355, 1);
$e_humberger   : cubic-bezier(.55, 0, .1, 1);
$e_slot        : cubic-bezier(.32, .95, .6, 1);
$e_scroll      : cubic-bezier(.32, .95, .6, 1);
$e_fade        : cubic-bezier(.32, .95, .6, 1);
$e_draw        : cubic-bezier(1, .6, .32, .78);
$e_pop        : cubic-bezier(0.68, -0.6, 0.32, 1.6),;
//$e_slot_child  : cubic-bezier(1, 1, .6, .6);

$t_pop: 1.2s $e_pop;
$t_curtain: 1s $e_curtain;
$t_btn    : $p_hv $e_curtain;
$t_hv     : $p_hv $e_slot;
$t_slide  : $p_hv $e_slot;
$t_scroll : $p_scroll $e_scroll;
$t_fade   : $p_hv + .6s $e_slot;
$t_slide_child : $p_slide $e_slot;
$t_transition  : .5s cubic-bezier(.32, .95, .6, 1);
$t_slideUp     : .9s cubic-bezier(.32, .95, .6, 1.3);
$t_bubble      : .4s cubic-bezier(.3, .41, .73, 1.03);
