/*
 * mv.scss
 *
 * メインビジュアル用コンポーネント
 * 主に下層ページで利用。
 */


.mv {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  box-sizing: border-box;
  @include responsive((
    width: 1680,
    margin-top: 280,
    margin-bottom: 96,
    padding: 0 40,
  ));
  @include mq(sp) {
    width: 100%;
    margin-top: mycalcSP(88);
    // margin-bottom: mycalcSP(64); //_txtのpadding-bottom分を減算
    margin-bottom: mycalcSP(44);
    padding: mycalcSP(0 24);
  }
}

.main_ttl {
  width: 100%;

  @include mq(sp) {
    margin-top: mycalcSP(96);
  }

  &_jp {
    color: $c_text;
    font-weight: 300;
    position: relative;
    @include responsive((
      font-size: 20,
      line-height: 18,
      padding-left: 28,
      margin-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(14);
      line-height: mycalcSP(16);
      padding-left: mycalcSP(16);
      margin-bottom: mycalcSP(16);
    }

    &:before {
      content: "";
      border-radius: 50%;
      background: $c_red;
      position: absolute;
      left: 0;
      @include responsive((
        width: 12,
        height: 12,
        top: 4,
      ));
      @include mq(sp) {
        width: mycalcSP(8);
        height: mycalcSP(8);
        top: mycalcSP(4);
      }
    }
  }

  &_en {
    font-family: $f_en;
    color: $c_text;
    position: relative;
    @include responsive((
      font-size: 96,
      line-height: 96,
    ));
    @include mq(sp) {
      font-size: mycalcSP(40);
      line-height: mycalcSP(40);
    }

    ._txt {
      @include responsive((
        padding-bottom: 20,
      ));
      @include mq(sp) {
        padding-bottom: mycalcSP(6);
      }
    }
  }

}

.main_visual {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  @include responsive((
    margin-bottom: 120,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(64);
  }

  &_figure {
    margin: 0 auto;
    mask-image: url("../images/common/bg/mask.png");
    mask-repeat: no-repeat;
    mask-size: 83.3% 100%;
    mask-position: 50% 100%;
    @include responsive((
      width: 1920,
      height: 800,
    ));
    @include mq(sp) {
      // mask-size: 87.6% 100%; // window bottom時のサイズ
      mask-size: 75.2% 100%; // 初期値が中央付近になるため、もう少し余白が必要
      width: mycalcSP(390);
      height: mycalcSP(256);
    }

    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

._txt._s {
  @include responsive((
    min-width: 28,
  ));
  @include mq(sp) {
    min-width: mycalcSP(20);
  }
}