.top_about {
  width: 100%;
  @include responsive((
    padding-top: 264,
    margin-bottom: 0,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(120);
  }

  &_figure {
    overflow: hidden;
    @include responsive((
      width: 1356,
      height: 648,
      margin-left: 204,
      margin-bottom: 120,
    ));
    @include mq(sp) {
      width: mycalcSP(326);
      height: mycalcSP(244);
      margin-left: mycalcSP(40);
      margin-bottom: mycalcSP(48);
    }

    &_img {
      
    }
  }

  &_ttl {
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
      padding-left: 204,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(40);
      padding-left: mycalcSP(0);
    }
  }

  &_desc {
    width: 100%;
    box-sizing: border-box;
    @include responsive((
      font-size: 18,
      line-height: 48,
      margin-bottom: 48,
      padding-left: 204,
    ));
    @include mq(sp) {
      font-size: mycalcSP(15);
      line-height: mycalcSP(30);
      margin-bottom: mycalcSP(40);
      padding-left: mycalcSP(0);
    }

    p {
      @include responsive((
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(16);
      }
    }
  }

  .btn {
    @include responsive((
      margin-left: 204,
    ));
    @include mq(sp) {
      margin-left: 0;
    }
  }
}


.top_business {
  width: 100%;

  &_head {
    z-index: -1;
    width: 100%;
    height: 100vh;
    margin-bottom: 100vh;
  }

  .sec_inner {
    position: relative;
    z-index: 0;
  }

  .headline {
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    @include responsive((
      width: 1280,
      padding: 0 40,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0 24);
    }
  }

  /*
  &_figure {
    margin: 0 auto;
    overflow: hidden;
    @include responsive((
      width: 846,
      height: 476,
      margin-bottom: 120,
    ));
    @include mq(sp) {
      width: mycalcSP(390);
      height: mycalcSP(218);
      margin-left: mycalcSP(-24);
      margin-bottom: mycalcSP(48);
    }
  }
  */

  &_visual {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @include responsive((
      margin-bottom: 120,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(48);
    }

    &_figure {
      // transform: scale(.83);
      transform: scale(0.5);
      margin: 0 auto;
      mask-image: url("../images/common/bg/mask.png");
      mask-repeat: no-repeat;
      mask-size: 44% 30%;
      mask-position: 50% 0;
      background-color: $c_hyacinth_light;
      width: 100vw;
      height: 100vh;
      isolation: isolate;
      /*
      @include responsive((
        width: 1600,
        height: 800,
      ));
      @include mq(sp) {
        width: mycalcSP(342);
        height: mycalcSP(256);
      }
      */

      &_video,
      &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &_ttl {
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(48);
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    &_item {
      border-top: 1px solid $c_gray;
      border-bottom: 1px solid $c_gray;
      @include responsive((
        width: 560,
        padding: 47 0 94,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(40 0 60);
      }
    }

    &_desc {
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        font-size: 18,
        line-height: 48,
        margin-bottom: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(40);
      }

      p {
        @include responsive((
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(16);
        }
      }
    }

    &_figure {
      width: 100%;
      @include responsive((
        height: 280,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        height: mycalcSP(171);
        margin-bottom: mycalcSP(24);
      }

      &_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &_name {
    position: relative;
    @include responsive((
      font-size: 32,
      line-height: 40,
      margin-bottom: 32,
      padding-left: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(24);
      line-height: mycalcSP(32);
      margin-bottom: mycalcSP(32);
      padding-left: mycalcSP(24);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      background: $c_red;
      border-radius: 50%;
      @include responsive((
        width: 8,
        height: 8,
        top: 18,
      ));
      @include mq(sp) {
        width: mycalcSP(8);
        height: mycalcSP(8);
        top: mycalcSP(12);
      }
    }
  }

  &_desc {
    width: 100%;
    box-sizing: border-box;
    @include responsive((
      font-size: 18,
      line-height: 48,
      margin-bottom: 48,
    ));
    @include mq(sp) {
      font-size: mycalcSP(15);
      line-height: mycalcSP(30);
      margin-bottom: mycalcSP(40);
    }

    p {
      @include responsive((
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(16);
      }
    }
  }

  .btn {
  }
}


.top_product {
  position: relative;
  background: $c_hyacinth;
  color: $c_white;
  @include responsive((
    padding: 200 0,
    margin-bottom: 0,
  ));
  @include mq(sp) {
    padding: mycalcSP(120 24);
    margin-bottom: 0;
  }

  .sec_inner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
  }

  .headline {
    align-self: flex-start;
    @include responsive((
      width: 1200,
      margin: 0 auto 94,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
    }

    &_jp {
      color: $c_white;
    }

    &_en {
      width: 100%;
      color: $c_white;
      position: absolute;
      display: flex;
      justify-content: center;
      align-self: center;
      left: 0;
      @include responsive((
        font-size: 320,
        line-height: 320,
        top: 564,
      ));
      @include mq(sp) {
        font-size: mycalcSP(48);
        line-height: mycalcSP(48);
        top: mycalcSP(380);
      }

      ._txt {
        padding-bottom: 0;
      }
    }
  }

  &_ttl {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(176);
      text-align: left;
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    @include responsive((
      margin-bottom: 280,
    ));
    @include mq(sp) {
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: mycalcSP(64);
    }

    &_item {
      text-align: center;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      // transition: transform $t_scroll;
      @include responsive((
        width: 544,
        margin: 0 32,
      ));
      @include mq(sp) {
        width: 100%;
        margin: mycalcSP(0 auto 48);
      }

      /*
      &.swiper-slide-prev,
      &.swiper-slide-next {
        @include responsive((
          transform-y: 200,
        ));
      }

      &.swiper-slide-prev-first,
      &.swiper-slide-next-last {
        @include responsive((
          transform-y: 800,
        ));
      }
      */

      &:nth-child(odd) {
        @include mq(sp) {
          margin-top: 0;
          margin-bottom: mycalcSP(48);
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: mycalcSP(48);
        }
      }

      @include mq(sp) {
        &:first-child {
          order: 100;
          margin-bottom: 0;
        }
      }

      &_name {
        font-weight: 400;
        @include responsive((
          font-size: 24,
          line-height: 40,
          margin-bottom: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(20);
          line-height: mycalcSP(36);
          margin-bottom: mycalcSP(16);
        }
      }

      &_desc {
        font-weight: 400;
        @include responsive((
          font-size: 16,
          line-height: 28,
          margin-bottom: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(14);
          line-height: mycalcSP(28);
          margin-bottom: mycalcSP(32);
          text-align: left;
        }
      }

      &_figure {
        order: -1;
        overflow: hidden;
        @include responsive((
          width: 544,
          height: 544,
          margin-bottom: 24,
        ));
        @include mq(sp) {
          width: mycalcSP(300);
          height: mycalcSP(300);
          // margin-bottom: mycalcSP(24); // pngで画像内に余白が生じたため、削除
          margin-bottom: 0;
        }
      }

      .btn {
        &_link {
          @include responsive((
            padding: 16 56 16 62,
          ));
          @include mq(sp) {
            padding: mycalcSP(16 56 16 62);
          }

          &_txt {
            @include responsive((
              font-size: 14,
              line-height: 16,
            ));
            @include mq(sp) {
              font-size: mycalcSP(14);
              line-height: mycalcSP(16);
            }
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
    overflow: visible;
  }
}


.top_technology {
  width: 100%;
  position: relative;
  @include responsive((
    padding-top: 264,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(120);
  }

  &_ttl {
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
    @include responsive((
      font-size: 40,
      line-height: 64,
      margin-bottom: 80,
      padding-left: 510,
    ));
    @include mq(sp) {
      font-size: mycalcSP(22);
      line-height: mycalcSP(40);
      margin-bottom: mycalcSP(40);
      padding-left: 0;
      padding-top: mycalcSP(32);
    }
  }

  &_desc {
    width: 100%;
    box-sizing: border-box;
    @include responsive((
      font-size: 18,
      line-height: 48,
      margin-bottom: 96,
      padding-left: 510,
    ));
    @include mq(sp) {
      font-size: mycalcSP(15);
      line-height: mycalcSP(30);
      margin-bottom: mycalcSP(48);
      padding-left: 0;
    }

    p {
      @include mq(sp) {
        margin-bottom: mycalcSP(16);
      }
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    &_item {
      position: relative;
      @include responsive((
        width: 560,
      ));
      @include mq(sp) {
        width: 100%;
        margin-bottom: mycalcSP(48);

        &:last-child {
          margin-bottom: 0;
        }
      }

      /*
      &_figure {
        position: relative;
        overflow: hidden;
        background-position: bottom center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        @include responsive((
          width: 560,
          height: 560,
          background-size: 560 800,
        ));
        @include mq(sp) {
          width: mycalcSP(342);
          height: mycalcSP(342);
          @include mq(sp) {
            background-size: mycalcSP(342 488);
          }
        }

        &.technology_ceramic_bearings {
          background-image: url("../images/top/technology_ceramic_bearings.jpg");
          background-position: bottom left 18.75vw;
          @include mq(sp) {
            background-image: url("../images/top/technology_ceramic_bearings_sp.jpg");
            background-position: bottom left mycalcSP(24);
          }
        }

        &.technology_stainless_bearings {
          background-image: url("../images/top/technology_stainless_bearings.jpg");
          background-position: bottom right 18.75vw;
          @include mq(sp) {
            background-image: url("../images/top/technology_stainless_bearings_sp.jpg");
            background-position: bottom right mycalcSP(24);
          }
        }

        &_img {
          display: none;
          position: absolute;
          bottom: 0;
          @include responsive((
            width: 560,
            height: 800,
          ));
          @include mq(sp) {
            width: mycalcSP(342);
            height: mycalcSP(342);
          }
        }
      }
      */

      &_figure {
        z-index: -1;
        position: relative;
        overflow: hidden;
        @include responsive((
          width: 560,
          height: 560,
        ));
        @include mq(sp) {
          width: mycalcSP(342);
          height: mycalcSP(342);
        }

        &_img {
          position: absolute;
          top: 0;
          left: 0;
          @include responsive((
            width: 560,
            height: 800,
          ));
          @include mq(sp) {
            width: mycalcSP(342);
            height: mycalcSP(488);
          }
        }
      }

      &_link {
        overflow: hidden;
        position: relative;
      }

      .btn_circle {
        position: absolute;
        @include responsive((
          width: 96,
          height: 96,
          right: 40,
          bottom: 40,
        ));
        @include mq(sp) {
          width: mycalcSP(56);
          height: mycalcSP(56);
          right: mycalcSP(16);
          bottom: mycalcSP(16);
        }
      }
    }
  }

  &_name {
    position: relative;
    @include responsive((
      font-size: 32,
      line-height: 40,
      margin-bottom: 32,
      padding-left: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(24);
      line-height: mycalcSP(32);
      margin-bottom: mycalcSP(24);
      padding-left: mycalcSP(24);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      background: $c_red;
      border-radius: 50%;
      @include responsive((
        width: 8,
        height: 8,
        top: 18,
      ));
      @include mq(sp) {
        width: mycalcSP(8);
        height: mycalcSP(8);
        top: mycalcSP(12);
      }
    }
  }


  .btn {
  }
}


.top_news {
  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    @include mq(sp) {
      justify-content: center;
      flex-wrap: wrap;
    }

    .btn {
      order: -1;
      @include mq(sp) {
        order: 0;
        justify-self: center;
      }
    }
  }

  .archive_list {
    @include responsive((
      width: 690,
    ));
    @include mq(sp) {
      width: 100%;
      order: -1;
      margin-bottom: mycalcSP(40);
    }
  }

}