.product_archive {
  box-sizing: border-box;
  margin: 0 auto;
  @include responsive((
    width: 1280,
    padding: 0 40,
    margin-top: 80,
    margin-bottom: 80,
  ));
  @include mq(sp) {
    width: 100%;
    padding: mycalcSP(0 24);
    margin-top: mycalcSP(48);
    margin-bottom: mycalcSP(64);
  }

  &_wrapper {
    width: 100%;
    @include responsive((
      margin-bottom: 264,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(120);
    }
  }

  &_ttl {
    width: 100%;
    @include responsive((
      margin-bottom: 24,
    ));
    @include minq(sp) {
      pointer-events: none;
    }
    @include mq(sp) {
      margin-bottom: mycalcSP(30);
    }
    &_txt {
      font-weight: 500;
      @include responsive((
        font-size: 24,
        line-height: 40,
      ));
      @include mq(sp) {
        font-size: mycalcSP(18);
        line-height: mycalcSP(28);
      }
    }
  }

  &_view {
    @include responsive((
      font-size: 16,
      line-height: 24,
      margin-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(14);
      line-height: mycalcSP(28);
      margin-bottom: mycalcSP(24);
    }

  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &_item {
      @include responsive((
        width: 288,
        margin-bottom: 40,
        margin-right: 16,
      ));
      @include minq(sp) {
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      @include mq(sp) {
        width: mycalcSP(160);
        margin-bottom: mycalcSP(32);
        margin-right: 0;

        &:nth-child(even) {
          margin-left: mycalcSP(22);
        }
      }

      &_link {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;

        &:hover {
          .product_archive_list_item_desc {
            opacity: .7;
          }
        }
      }

      &_desc {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        transition: opacity $t_hv;

        &_ttl {
          @include responsive((
            font-size: 16,
            line-height: 24,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(20);
          }
        }

        &_cat {
          order: -1;
          line-height: 1;
          @include responsive((
            margin-bottom: 8,
          ));
          @include mq(sp) {
            margin-bottom: mycalcSP(4);
          }

          .cat {
            display: inline-block;
            color: $c_hyacinth;
            @include responsive((
              font-size: 14,
              line-height: 18,
              margin-right: 8,
            ));
            @include mq(sp) {
              font-size: mycalcSP(10);
              line-height: mycalcSP(14);
              margin-right: mycalcSP(4);
            }
          }
        }
      }

      &_figure {
        order: -1;
        overflow: hidden;
        @include responsive((
          width: 288,
          height: 162,
          margin-bottom: 8,
        ));
        @include mq(sp) {
          width: mycalcSP(160);
          height: mycalcSP(90);
          margin-bottom: mycalcSP(4);
        }

        &_img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}


.product_archive_tax {
  margin: 0 auto;
  box-sizing: border-box;
  @include responsive((
    width: 1280,
    margin-bottom: 80,
    padding: 0 40,
  ));
  @include mq(sp) {
    width: 100%;
    margin-bottom: mycalcSP(48);
    padding: mycalcSP(0 24);
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &_wrap {
      @include minq(sp) {
        display: block;
      }
    }
  }

  &_item {
    @include responsive((
      width: 176,
      margin-right: 28.8,
      margin-top: 24,
    ));
    @include mq(sp) {
      width: mycalcSP(96);
      margin-right: mycalcSP(27);
      margin-top: mycalcSP(16);
    }
    @include minq(sp) {
      &:nth-child(6n) {
        margin-right: 0;
      }
      &:nth-child(-n+6) {
        margin-top: 0;
      }
    }
    @include mq(sp) {
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(-n+4) {
        margin-top: 0;
      }
    }

    &_link {
      &_figure {
        overflow: hidden;
        background: $c_hyacinth_light;
        @include responsive((
          width: 176,
          height: 99,
        ));
        @include mq(sp) {
          width: mycalcSP(96);
          height: mycalcSP(54);
        }

        &_img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &_name {
        @include responsive((
          font-size: 14,
          line-height: 18,
          margin-top: 8,
        ));
        @include mq(sp) {
          font-size: mycalcSP(11);
          line-height: mycalcSP(14);
          margin-top: mycalcSP(4);
        }
      }
    }
  }

  .btn_plus {
    display: none;

    @include mq(sp) {
      display: block;
      top: 50%;
      margin-top: mycalcSP(-20);
    }
  }
  
}


.product_archive_search {
  margin: 0 auto;
  box-sizing: border-box;
  @include responsive((
    width: 1280,
    margin-bottom: 80,
    padding: 0 40,
  ));
  @include mq(sp) {
    width: 100%;
    margin-bottom: mycalcSP(48);
    padding: mycalcSP(0 24);
  }

  &_condition {
    background: $c_white;
    @include responsive((
      padding: 40 40 48,
    ));
    @include minq(sp) {
      display: block;
    }
    @include mq(sp) {
      padding: mycalcSP(16 16 24);
    }
  }

  .btn_plus {
    display: none;

    @include mq(sp) {
      display: block;
      top: 50%;
      margin-top: mycalcSP(-20);
    }
  }
  
}


.product_search_form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &_label {
    font-weight: 500;
    @include responsive((
      font-size: 16,
      line-height: 24,
      margin-right: 64,
    ));
    @include mq(sp) {
      width: 100%;
      font-size: mycalcSP(16);
      line-height: mycalcSP(24);
      margin-right: 0;
      margin-bottom: mycalcSP(8);
    }
  }

  &_input {
    box-sizing: border-box;
    border: 1px solid $c_gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include responsive((
      width: 976,
      height: 56,
    ));
    @include mq(sp) {
      width: 100%;
      height: mycalcSP(48);
    }

    &_text {
      border: 0;
      box-sizing: border-box;
      @include responsive((
        width: 882,
        height: 54,
        font-size: 16,
        line-height: 24,
        padding: 16,
      ));
      @include mq(sp) {
        width: mycalcSP(230);
        height: mycalcSP(46);
        font-size: mycalcSP(14);
        line-height: mycalcSP(24);
        padding: mycalcSP(8);
      }
    }

    &_btn {
      position: relative;

      &_submit[type="submit"] {
        background-color: $c_hyacinth;
        color: $c_white;
        font-weight: 500;
        box-sizing: border-box;
        transition: background-color $t_hv;
        @include responsive((
          width: 94,
          height: 56,
          font-size: 16,
          line-height: 24,
          padding-left: 20,
        ));
        @include mq(sp) {
          width: mycalcSP(80);
          height: mycalcSP(48);
          font-size: mycalcSP(13);
          line-height: mycalcSP(18);
          padding-left: mycalcSP(20);
        }

        &:hover {
          background-color: $c_hyacinth_opacity;
        }
      }

      &:before {
        content: "";
        background-image: url("../images/common/ico/search.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        @include responsive((
          width: 16,
          height: 16,
          margin-top: -6,
          left: 20,
        ));
        @include mq(sp) {
          width: mycalcSP(14);
          height: mycalcSP(14);
          margin-top: mycalcSP(-6);
          left: mycalcSP(18);
        }
      }
    }
  }
}


.product_check_form {

  &_inner {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid $c_gray;
    border-bottom: 1px solid $c_gray;
    @include responsive((
      margin-top: 40,
      padding: 40 0,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(24);
      padding: mycalcSP(16 0 8);
    }
  }

  &_label {
    font-weight: 500;
    @include responsive((
      font-size: 16,
      line-height: 24,
      margin-right: 64,
    ));
    @include mq(sp) {
      width: 100%;
      font-size: mycalcSP(16);
      line-height: mycalcSP(24);
      margin-right: 0;
      margin-bottom: mycalcSP(16);
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      width: 976,
    ));
    @include mq(sp) {
      width: 100%;
    }

    &_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include responsive((
        margin-right: 32,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(20);
        margin-bottom: mycalcSP(16);
      }

      &_check {
        box-sizing: border-box;
        border: 1px solid $c_gray;
        transition: background-color $t_btn;
        @include responsive((
          width: 20,
          height: 20,
          margin-right: 8,
        ));
        @include mq(sp) {
          width: mycalcSP(20);
          height: mycalcSP(20);
          margin-right: mycalcSP(4);
        }

        &:before {
          content: "";
          position: absolute;
          background: $c_hyacinth;
          opacity: 0;
          transition: opacity .4s $e_fade;
          @include responsive((
            width: 12,
            height: 12,
            top: 3,
            left: 3,
          ));
          @include mq(sp) {
            width: mycalcSP(12);
            height: mycalcSP(12);
            top: mycalcSP(3);
            left: mycalcSP(3);
          }
        }

        &:checked {
          position: relative;
          
          &:before {
            opacity: 1;
          }
        }
      }

      &_label {
        @include responsive((
          font-size: 16,
          line-height: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(14);
          line-height: mycalcSP(18);
        }
      }
    }
  }

  &_btn {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    @include responsive((
      width: 216,
      height: 56,
      border-radius: 28,
      margin-top: 40,
    ));
    @include mq(sp) {
      width: mycalcSP(202);
      height: mycalcSP(56);
      border-radius: mycalcSP(28);
      margin-top: mycalcSP(16);
    }

    &_submit[type="submit"] {
      color: $c_white;
      background: $c_hyacinth;
      font-weight: bold;
      transition: background-color $t_hv;
      @include responsive((
        font-size: 16,
        line-height: 16,
        padding-left: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(14);
        line-height: mycalcSP(16);
        padding-left: mycalcSP(24);
      }

      &:hover {
        background-color: $c_hyacinth_opacity;
      }
    }

    &:before {
      content: "";
      background-image: url("../images/common/ico/search.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      @include responsive((
        width: 16,
        height: 16,
        margin-top: -6,
        left: 36,
      ));
      @include mq(sp) {
        width: mycalcSP(14);
        height: mycalcSP(14);
        margin-top: mycalcSP(-6);
        left: mycalcSP(36);
      }
    }

  }
}


.product_category {
  position: relative;
  @include responsive((
    padding-bottom: 120,
  ));
  @include mq(sp) {
    padding-bottom: mycalcSP(64);
  }

  &:after {
    content: "";
    border-bottom: 1px solid $c_gray;
    position: absolute;
    left: 50%;
    bottom: 0;
    @include responsive((
      width: 1200,
      margin-left: -600,
    ));
    @include mq(sp) {
      width: mycalcSP(342);
      margin-left: mycalcSP(-171);
    }
  }
  
  &_ttl {
    font-weight: 500;
    margin: 0 auto;
    @include responsive((
      width: 1600,
      font-size: 44,
      line-height: 64,
      margin-bottom: 64,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: mycalcSP(0 24);
      font-size: mycalcSP(24);
      line-height: mycalcSP(36);
      margin-bottom: mycalcSP(32);
    }
  }

  &_visual {
    width: 100%;
    overflow: hidden;
    @include responsive((
      margin-bottom: 120,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
    }

    &_figure {
      margin: 0 auto;
      mask-image: url("../images/common/bg/mask.png");
      mask-repeat: no-repeat;
      mask-size: 83.3% 100%;
      mask-position: 50% 100%;
      @include responsive((
        width: 1920,
        height: 900,
      ));
      @include mq(sp) {
        width: mycalcSP(342);
        height: mycalcSP(256);
      }

      &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}


.tax {
  .product_archive {
    // border-bottom: 1px solid $c_gray;
    @include responsive((
      margin-bottom: 96,
      // padding-bottom: 120,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
      // padding-bottom: mycalcSP(64);
    }
  }

  .product_archive_search {
    @include responsive((
      margin-top: 96,
      margin-bottom: 264,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(64);
      margin-bottom: mycalcSP(120);
    }
  }
  
}


.product {
  .contents_post {
    padding-left: 0;
    padding-right: 0;
    @include responsive((
      max-width: 1200,
    ));
    @include mq(sp) {
      max-width: mycalcSP(342);
    }
  }

  .post_meta_cat .cat {
    color: $c_text;
  }

  &_pagenation {
    margin: 0 auto;
  }
}


.product_images {
  @include responsive((
    padding-bottom: 96,
  ));
  @include mq(sp) {
    padding-bottom: mycalcSP(64);
    overflow: visible;
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    &_item {
      @include responsive((
        width: 368,
        margin: 0 16,
        margin-bottom: 40,
      ));
      @include mq(sp) {
        width: mycalcSP(288);
        margin-bottom: mycalcSP(24);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.product_images_single {
        @include responsive((
          width: 800,
          margin: 0 auto 40,
        ));
        @include mq(sp) {
          width: mycalcSP(288);
          margin: 0 auto mycalcSP(24);
        }
      }

      &.product_images_double {
        margin-left: 0;
        @include responsive((
          width: 576,
          margin-right: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(288);
          margin-right: auto;

          margin-left: auto;
        }

        &:nth-child(even){
          margin-right: 0;

          @include mq(sp) {
            margin-right: auto;
          }
        }
      }

      &_figure {
        width: 100%;

        &_inner {
          display: block;
          width: 100%;
          overflow: hidden;
          @include responsive((
            height: 207,
          ));
          @include mq(sp) {
            height: mycalcSP(162);
          }

          .product_images_single & {
            @include responsive((
              height: 450,
            ));
            @include mq(sp) {
              height: mycalcSP(162);
            }
          }

          .product_images_double & {
            @include responsive((
              height: 324,
            ));
            @include mq(sp) {
              height: mycalcSP(162);
            }
          }
        }

        &_img {
          object-fit: cover;
          display: block;
          width: 100%;
          height: 100%;
        }

        &_txt {
          @include responsive((
            font-size: 14,
            line-height: 18,
            margin-top: 4,
          ));
          @include mq(sp) {
            font-size: mycalcSP(11);
            line-height: mycalcSP(14);
            margin-top: mycalcSP(4);
          }
        }
      }
    }
  }

  &_pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive((
      height: 56,
      margin-top: 32,
    ));
    @include mq(sp) {
      height: mycalcSP(40);
      margin-top: mycalcSP(24);
    }

    &_btn {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      @include responsive((
        width: 136,
      ));
      @include minq(sp) {
        .ico_arw {
          display: none;
        }
      }
      @include mq(sp) {
        width: mycalcSP(40);

        .btn_circle:before,
        .btn_circle:after {
          content: none;
        }
        .ico_arw {
          position: absolute;
          width: mycalcSP(16);
          height: mycalcSP(16);
          top: 50%;
          left: 50%;
          margin-top: mycalcSP(-8);
          margin-left: mycalcSP(-8);

          path {
            fill: $c_text;
          }
        }
      }

      &_txt {
        font-family: $f_en;
        @include responsive((
          font-size: 14,
          line-height: 20,
          margin: 0 16,
        ));
        @include mq(sp) {
          display: none;
        }
      }

      &.swiper-button-prev {
        @include responsive((
          margin-right: 104,
        ));
        @include mq(sp) {
          margin-right: mycalcSP(64);
        }
        .ico_arw {
          transform: rotate(-90deg);
        }
      }

      &.swiper-button-next {
        @include responsive((
          margin-left: 104,
        ));
        @include mq(sp) {
          margin-left: mycalcSP(64);
        }
        .ico_arw {
          transform: rotate(90deg);
        }
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      .swiper-pagination-bullet {
        cursor: pointer;
        background: $c_black;
        transition: background-color $t_btn;
        border-radius: 50%;
        display: block;
        @include responsive((
          width: 6,
          height: 6,
          margin: 0 4,
        ));
        @include mq(sp) {
          width: mycalcSP(6);
          height: mycalcSP(6);
          margin: mycalcSP(0 4);
        }
      }

      .swiper-pagination-bullet-active {
        background: $c_red;
      }
    }
  }

}