/*
 * pagenation.scss
 *
 * ページャー／ページネーション用コンポーネント
 */


// 詳細ページのページャー想定
.pager {
  width: 100%;
  box-sizing: border-box;

  &_list {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    @include responsive((
      margin-top: 96,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(56);
    }

    &_num {
      @include responsive((
        width: 48,
        height: 48,
        margin: 0 4,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin: mycalcSP(8 4 0);
      }

      span, a {
        @include flex(row, center, center, none, wrap);
        box-sizing: border-box;
        color: $c_text;
        transition: color $p_over cubic-bezier(.23, 1, .32, 1);
        font-family: $f_en;
        letter-spacing: 0;
        position: relative;
        overflow: hidden;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        @include responsive((
          font-size: 16,
          line-height: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(14);
          line-height: mycalcSP(14);
        }

        &:before {
          content: "";
          width: 0;
          height: 0;
          background: $c_white;
          position: absolute;
          bottom: 50%;
          left: 50%;
          transition: width $p_over cubic-bezier(.23, 1, .32, 1), height $p_over cubic-bezier(.23, 1, .32, 1), bottom $p_over cubic-bezier(.23, 1, .32, 1), left $p_over cubic-bezier(.23, 1, .32, 1), background $p_over cubic-bezier(.23, 1, .32, 1);
          z-index: -1;
          border-radius: 50%;
        }
      }

      a {
        &:hover {
          color: $c_white;

          &:before {
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background: $c_red;
          }
        }
      }

      .current {
        color: $c_white;
        background: $c_red;

        &:before {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          background: none;
          @include responsive((
            border-radius: 40,
          ));
          @include mq(sp) {
            border-radius: mycalcSP(40);
          }
        }
      }

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }

    }
  }
}


// 一覧ページのページャー想定
.pagenation {
  width: 100%;
  margin: 0 auto;
  @include flex(row, space-between, center, none, wrap);
  @include responsive($list: (
    max-width : 680,
    padding: 96 0 0,
  ));
  @include mq(sp) {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: mycalcSP(64 0 0);
    justify-content: center;
  }

  @include mq(sp) {
    .prev,
    .next {
      position: absolute;
      top: mycalcSP(64);
      margin-top: 0;
    }

    .prev {
      left: mycalcSP(24);
    }

    .next {
      right: mycalcSP(24);
    }

    .list {
      margin: 0 auto;
      top: mycalcSP(72);
    }
  }
}


.pagenation,
.pager {
  .prev,
  .next {
    @include responsive((
      width: 120,
      height: 56,
    ));
    @include mq(sp) {
      width: mycalcSP(40);
      height: mycalcSP(40);
      margin-top: mycalcSP(8);
    }

    &_link {
      display: flex;
      align-items: center;
      @include mq(sp) {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 100%;
        height: 100%;
      }

      &_txt {
        font-family: $f_en;
        letter-spacing: .1em;
        @include responsive((
          font-size: 14,
          line-height: 20,
        ));
        @include mq(sp) {
          display: none;
        }
      }

      .btn_circle {
        @include mq(sp) {
          display: none;
        }
      }
    }
  }

  .prev {
    @include responsive((
      margin-right: 120,
    ));
    @include mq(sp) {
      margin-right: mycalcSP(8);
    }

    &_link {
      justify-content: flex-start;
      @include mq(sp) {
        background-image: url('../images/common/ico/prev.svg');
      }

      &_txt {
        @include responsive((
          margin-left: 16,
        ));
      }
    }
  }

  .next {
    @include responsive((
      margin-left: 120,
    ));
    @include mq(sp) {
      margin-left: mycalcSP(8);
    }

    &_link {
      justify-content: flex-end;
      @include mq(sp) {
        background-image: url('../images/common/ico/next.svg');
      }

      &_txt {
        @include responsive((
          margin-right: 16,
        ));
      }
    }
  }
}
