/*
 * opening.scss
 */

.opening {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background: #000;

  @include mq(sp) {
    z-index: 0;
  }

  &_logo {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    @include responsive((
      width: 1136,
    ));
    @include mq(sp) {
      width: 100%;
      height: mycalcSP(32);
      flex-flow: row;
      justify-content: center;
      top: 50%;
      margin-top: mycalcSP(-218);
      position: absolute;
    }

    .ico_logo {
      height: auto;
      @include responsive((
        width: 300,
      ));
      @include mq(sp) {
        width: mycalcSP(200);
      }

    }
    path {
      fill: $c_black;
    }
  }

  &_figure {
    position: absolute;
    overflow: hidden;
    top: 50%;
    right: 50%;
    /*
    width: 100%;
    height: 100%;
    margin-top: -50vh;
    margin-right: -50vw;
    border-radius: 0 0 0 0;
    */
    will-change: transform, width, height, margin, border-radius;
    @include responsive((
      width: 520,
      height: 800,
      border-radius: 260 260 0 0,
      margin-top: -400,
      margin-right: -570,
    ));
    @include mq(sp) {
      width: mycalcSP(260);
      height: mycalcSP(400);
      margin-top: mycalcSP(-90);
      margin-right: mycalcSP(-130);
      border-radius: mycalcSP(130 130 0 0);
    }

    &_img {
      position: absolute;
      max-width: none;
      top: 50%;
      right: 50%;
      will-change: transform, width, height, margin;
      @include responsive((
        width: 2660,
        height: 1774,
        margin-top: -887,
        margin-right: -610,
        //margin-right: -1330,
      ));
      @include mq(sp) {
        width: mycalcSP(1536);
        height: mycalcSP(1024);
        margin-top: mycalcSP(-512);
        margin-right: mycalcSP(-340);
        //margin-right: mycalcSP(-768);
      }
    }
  }
}
