/*
 * form.scss
 *
 * フォーム用コンポーネント
 */

$f_min-height: 64px;

::placeholder {
  color: $c_placeholder;
}

.form {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @include responsive((
    max-width: 1280,
    padding: 0 40,
    margin-bottom: 264,
  ));
  @include mq(sp) {
    padding: mycalcSP(0 24 24);
    margin-bottom: mycalcSP(120);
  }

  &_desc {
    @include responsive((
      padding-bottom: 96,
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(64);
    }

    p {
      text-align: center;
      @include responsive((
        font-size: 18,
        line-height: 32,
      ));
      @include mq(sp) {
        text-align: left;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
      }
    }
  }

  &_area {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    border-top: 1px solid $c_gray;
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $c_gray;
    box-sizing: border-box;
    flex-wrap: wrap;
    position: relative;
    @include responsive((
      padding : 40 0
    ));
    @include mq(sp) {
      padding: mycalcSP(32 0);
    }

    &_ttl {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include responsive((
        width : 266,
        min-height: 64,
        margin-right: 40,
      ));
      @include mq(sp) {
        width: 100%;
        min-height: auto;
        justify-content: space-between;
        margin-bottom: mycalcSP(16);
      }

      .form_list_box &,
      .confirmation & {
        height: auto;
        @include mq(sp) {
          margin-bottom: mycalcSP(16);
        }
      }

      &_txt {
        font-weight: 500;
        display: block;
        @include responsive((
          width : 222,
          font-size: 18,
          line-height: 32,
        ));
        @include mq(sp) {
          width: auto;
          font-size: mycalcSP(16);
          line-height: mycalcSP(24);
        }

        small {
          font-weight: bold;
          @include responsive((
            font-size: 14,
            line-height: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(24);
          }
        }
      }

      &_must {
        color: $c_red;
        border: 1px solid $c_red;
        text-align: center;
        font-weight: bold;
        white-space: nowrap;
        @include responsive((
          padding : 4 8,
          font-size: 14,
          line-height: 20,
          border-radius: 4,
        ));
        @include mq(sp) {
          box-sizing: border-box;
          text-align: center;
          width: mycalcSP(40);
          padding: mycalcSP(2 8);
          font-size: mycalcSP(12);
          line-height: mycalcSP(20);
          border-radius: mycalcSP(4);
        }
      }
    }

    &_cnt {
      @include responsive((
        width: 894,
        font-size: 18,
        line-height: 32,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
      }
      &.short {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq(sp) {
          flex-wrap: wrap;
        }
        .short_list {
          @include responsive((
            margin-right: 20,
          ));
          @include mq(sp) {
            width: mycalcSP(140);
            margin-right: mycalcSP(20);
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &_ipt {
        font-size: inherit;
        box-sizing: border-box;
        border: 1px solid $c_gray;
        background: $c_white;
        font-family: $f_gothic;
        @include responsive((
          width : 894,
          height : 64,
          font-size: 18,
          line-height: 32,
          padding : 16,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(50);
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
          padding: mycalcSP(10 16);
        }

        .short & {
          @include responsive((
            width : 240,
          ));
          @include mq(sp) {
            width: 100%;
            margin-bottom: mycalcSP(16);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &_txtarea {
        border: 1px solid $c_gray;
        background: $c_white;
        box-sizing: border-box;
        letter-spacing: 0;
        font-family: $f_gothic;
        @include responsive((
          width : 894,
          height : 256,
          padding : 16,
          font-size: 18,
          line-height: 32,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(172);
          padding: mycalcSP(10 16);
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
        }
      }

      &_select {
        display: block;
        position: relative;
        @include responsive((
          width : 894,
          height : 64,
          padding : 16,
          font-size: 18,
          line-height: 32,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(50);
          padding: mycalcSP(10 16);
          font-size: mycalcSP(15);
          line-height: mycalcSP(30);
        }

        &_wrap {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            border-left: 1px solid $c_text;
            border-bottom: 1px solid $c_text;
            transform: rotate(-45deg);
            @include responsive((
              width: 8,
              height: 8,
              right: 28,
              margin-top: -4,
            ));
            @include mq(sp) {
              width: mycalcSP(8);
              height: mycalcSP(8);
              right: mycalcSP(22);
              margin-top: mycalcSP(-4);
            }

            .confirmation & {
              content: none;
            }
          }
        }

        .form_select {

          display: block;
          width: 100%;
          height: 100%;

          * {
            position: relative;
            margin: 0;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-color: inherit;
          }

          ul {
            list-style: none;
            padding: 0;
          }

          input {
            color: inherit;
            font: inherit;
            margin: 0;
            background: transparent;
            outline: none;
            border: none;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: none;
          }

          li::before {
            display: none;
          }

          &_box {
            width: 100%;
            /*height: 100%;*/
            cursor: pointer;
            background: $c_gray_light;

            &_expand {
              width: 0;
              height: $f_min-height;
              position: absolute;
              top: 0;
              right: 0;

              &:checked {

                & + .form_select_box_close_label {
                  display: block;

                  & + .form_select_box_options {

                    .form_select_box_label {
                      height: $f_min-height;

                      &:hover {
                        background-color: $c_gray;
                      }
                    }

                    & + .form_select_box_expand_label {
                      //display: none;

                      &::after {
                        -webkit-transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
                        transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
                      }
                    }

                  }

                }
              }


              &_label {
                display: block;
                width: 100%;
                height: $f_min-height;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;

                &::after {
                  position: absolute;
                  top: 1.2em;
                  right: 0.5em;
                  width: 0;
                  height: 0;
                  padding: 0;
                  content: '';
                  pointer-events: none;
                  border-top: 6px solid $c_gray;
                  border-right: 6px solid transparent;
                  border-left: 6px solid transparent;
                  -webkit-transform: translate(-50%, -50%) rotate(0deg) scaleY(1.75);
                  transform: translate(-50%, -50%) rotate(0deg) scaleY(1.75);
                  -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
                  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
                  z-index: 2;
                }

                &:hover::after {
                  opacity: 1;
                }
              }
            }

            &_close {
              display: none;

              &_label {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
              }
            }

            &_items {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              border: 1px solid $c_gray;
              padding-top: $f_min-height;
              z-index: 1;
            }

            &_input {
              display: none;

              &:checked + .form_select_box_label {
                height: $f_min-height;
                margin-top: -$f_min-height;
              }
            }

            &_label {
              -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
              transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              height: 0;
              overflow: hidden;
              background-color: $c_white;
              cursor: pointer;
              @include responsive((
                padding : 0 16
              ));
            }

            &_placeholder {
              height: $f_min-height;
              vertical-align: middle;
              position: absolute;
              top: 0;
              left: 0;
              background-color: transparent;
              color: $c_placeholder;
            }
          }
        }
      }

      .vertical-item {
        display: block;

        @include mq(sp) {
          > label {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: mycalcSP(12);

            .mwform-radio-field-text {
              line-height: 1.6;
              letter-spacing: 0;
            }
          }
        }
      }

      .note {
        display: block;
        letter-spacing: 0;
        @include responsive((
          font-size: 12,
          line-height: 24,
          margin-top: 8,
        ));
        @include mq(sp) {
          font-size: mycalcSP(12);
          line-height: mycalcSP(16);
          margin-top: mycalcSP(16);
        }
      }

      .other {
        @include responsive((
          margin-top: 8,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(8);
        }
      }
    }

    &_desc {
      @include responsive((
        font-size: 13,
        line-height: 24,
        padding : 8 0 20,
      ));
      @include mq(sp) {
        padding: mycalcSP(5 0 10);
        font-size: mycalcSP(13);
        line-height: mycalcSP(24);
      }

      .mw_wp_form_confirm & {
        display: none;
      }
    }
  }
}


.mwform-radio-field,
.mwform-checkbox-field {
  label {
    position: relative;
    @include responsive((
      padding-left : 20
    ));
    @include mq(sp) {
      padding-left: mycalcSP(18);
    }

    .mwform-radio-field-text {
      &::before,
      &::after {
        border-radius: 50%;
      }
    }

    .mwform-radio-field-text,
    .mwform-checkbox-field-text {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        box-sizing: border-box;
      }

      &::before {
        background: $c_white;
        border: 1px solid $c_gray_dark;
        left: 0;
        transition: border-color $t_hv;
        @include responsive((
          width : 20,
          height : 20,
          margin-top : -10
        ));
        @include mq(sp) {
          width: mycalcSP(16);
          height: mycalcSP(16);
          margin-top: mycalcSP(5);
          top: 0;
        }
      }

      &::after {
        transition: background $t_hv;
        @include responsive((
          width : 12,
          height : 12,
          margin-top : -6,
          left : 4
        ));
        @include mq(sp) {
          width: mycalcSP(10);
          height: mycalcSP(10);
          margin-top: mycalcSP(-4);
          left: mycalcSP(3);
        }
      }
    }
  }

  input:checked {
    + .mwform-radio-field-text::before,
    + .mwform-checkbox-field-text::before {
      border-color: $c_red;
    }

    + .mwform-radio-field-text::after,
    + .mwform-checkbox-field-text::after {
      background: $c_red;
    }
  }
}


input[type="submit"] {
  outline: 0;
  width: 100%;
  height: 100%;
  border: 0;
  letter-spacing: .1em;
  cursor: pointer;
  background: none;
  color: $c_text;
  font-weight: bold;
  opacity: 1;
  transition: background-color .4s;
  font-family: $f_gothic;
  @include responsive((
    font-size: 16,
    line-height: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(14);
    line-height: mycalcSP(24);
  }

  &:disabled {
    cursor: no-drop;
    background: $c_gray_light;
    color: $c_hyacinth_light;
    -webkit-text-fill-color: $c_hyacinth_light;
    z-index: 1;
  }
}

.error {
  &_message {
    color: $c_red;
    font-weight: bold;
    margin: 50px auto;
    @include responsive((
      margin : 50 auto,
    ));
  }

  &_field {
    .form_list_cnt_ipt,
    .form_list_cnt_txtarea,
    .form_list_cnt_select .form_select_box_items,
    .form_list_cnt_set_label::before,
    .mwform-radio-field label .mwform-radio-field-text::before,
    .mwform-radio-field label .mwform-checkbox-field-text::before,
    .mwform-checkbox-field label .mwform-radio-field-text::before,
    .mwform-checkbox-field label .mwform-checkbox-field-text::before {
      border: 2px solid $c_red;
    }

    &_msg,
    .error,
    .mw_wp_form & .error {
      color: $c_red;
      font-weight: bold;
      padding-top: .2em;
    }
  }
}


.more_area {
  text-align: center;
  .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* TODO:.errorのスタイルを指定 */