/*
 * footer.scss
 *
 * フッター用コンポーネント
 */

.footer {
  width: 100%;
  background: $c_bg_footer;
  color: $c_white;
  position: relative;
  z-index: 0;
  @include responsive((
    padding: 120 0 110,
  ));
  @include mq(sp) {
    padding: mycalcSP(80 0);
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}


.footer_inner {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  @include responsive((
    width: 1280,
    padding: 0 40,
  ));
  @include mq(sp) {
    width: 100%;
    padding: mycalcSP(0 24);
    flex-flow: column;
  }
}


.footer_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid $c_hyacinth_light;
  position: relative;
  @include responsive((
    padding-bottom: 62,
  ));
  @include mq(sp) {
    flex-flow: column;
    padding-bottom: mycalcSP(48);
    margin-bottom: mycalcSP(32);
    border-bottom: 0;
  }
}


.footer_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  @include responsive((
    padding-top: 132,
  ));
  @include mq(sp) {

  }
}


.footer_touchpoint {
  box-sizing: border-box;
  position: relative;
  @include responsive((
    width: 332,
    padding-right: 40,
  ));
  @include mq(sp) {
    width: 100%;
    margin-right: 0;
    border-top: 1px solid $c_hyacinth_light;
    padding-top: mycalcSP(64);
  }
}


.footer_logo {
  box-sizing: border-box;
  @include responsive((
    width: 219,
    margin-bottom: 18,
  ));
  @include mq(sp) {
    width: mycalcSP(154);
    margin-bottom: mycalcSP(26);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &_link {
    text-align: center;
    display: block;
    width: 100%;

    img, svg {
      width: 100%;
      height: auto;
    }
  }
}


.footer_company {
  &_name {
    letter-spacing: 0;
    font-weight: 700;
    @include responsive((
      font-size: 16,
      line-height: 28,
      margin-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(16);
      line-height: mycalcSP(28);
      margin-bottom: mycalcSP(16);
    }
  }

  &_access,
  &_address {
    letter-spacing: 0;
    @include responsive((
      font-size: 16,
      line-height: 32,
      margin-bottom: 32,
    ));
    @include mq(sp) {
      font-size: mycalcSP(13);
      line-height: mycalcSP(28);
      margin-bottom: mycalcSP(28);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_address {
    &_line {
      display: block;
      @include responsive((
        margin-bottom: 8,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(8);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}


.footer_nav {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  @include responsive((
    width: 868,
    padding-left: 40,
  ));
  @include mq(sp) {
    width: 100%;
    margin-bottom: mycalcSP(32);
    padding-left: 0;
    order: -1;
  }

  &_list {
    @include responsive((
      width: 200,
      margin-left: 64,
    ));
    @include mq(sp) {
      width: 100%;
      margin-left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &_item {
      width: 100%;
      border-top: 1px solid $c_hyacinth_light;
      @include responsive((
        padding-top: 20,
        margin-bottom: 38,
      ));
      @include mq(sp) {
        width: 50%;
        box-sizing: border-box;
        padding-top: 0;
        margin-bottom: mycalcSP(7);
        border-top: 0;

        &:nth-child(odd) {
          padding-right: mycalcSP(12);
        }

        &:nth-child(even) {
          padding-left: mycalcSP(12);
        }
      }

      &:last-child {
        margin-bottom: 0;

        @include mq(sp) {
          margin-bottom: mycalcSP(7);
        }
      }

      &_link {
        position: relative;
        letter-spacing: 0;
        font-weight: 700;
        @include responsive((
          font-size: 16,
          line-height: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(14);
          line-height: mycalcSP(20);
          border-top: 1px solid $c_gray;
          padding: mycalcSP(24 0);
          display: block;
        }

        /*
        &:before {
          content: "";
          width: 100%;
          border-bottom: 1px solid $c_white;
          opacity: 0;
          right: 0;
          transform: scaleX(0);
          display: block;
          position: absolute;
          transition: transform $t_slide, opacity $t_slide;
          @include responsive((
            bottom: -6,
          ));
          @include mq(sp) {
            bottom: mycalcSP(-6);
          }
        }

        @include minq(sp) {
          &:hover {
            &:before {
              opacity: 1;
              transform: scaleX(1);
            }
          }
        }
        */
      }

      &_txt {
        letter-spacing: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    &_child {
      @include responsive((
        margin-top: 14,
        padding-bottom: 10,
      ));
      @include mq(sp) {
        margin-top: 0;
        margin-bottom: mycalcSP(24);
      }

      &_item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @include responsive((
          margin-bottom: 8,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(8);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &_link {
          position: relative;
          font-weight: 400;
          @include responsive((
            font-size: 14,
            line-height: 24,
          ));
          @include mq(sp) {
            font-size: mycalcSP(13);
            line-height: mycalcSP(24);
          }

          /*
          &:before {
            content: "";
            width: 100%;
            border-bottom: 1px solid $c_white;
            opacity: 0;
            right: 0;
            transform: scaleX(0);
            display: block;
            position: absolute;
            transition: transform $t_slide, opacity $t_slide;
            @include responsive((
              bottom: -6,
            ));
            @include mq(sp) {
              bottom: mycalcSP(-6);
            }
          }

          &:hover {
            &:before {
              opacity: 1;
              transform: scaleX(1);
            }
          }
          */
        }
        &_txt {
          letter-spacing: inherit;
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
        }
      }
    }
  }

  .ico_arw {
    @include responsive((
      width: 16,
      height: 16,
    ));
    @include mq(sp) {
      width: mycalcSP(16);
      height: mycalcSP(16);
      margin-right: mycalcSP(4);
    }
  }
}


.footer_privacy {
  width: 100%;
  letter-spacing: 0;
  @include responsive((
    font-size: 14,
    line-height: 28,
    margin-top: 60,
  ));
  @include mq(sp) {
    font-size: mycalcSP(12);
    line-height: mycalcSP(24);
    margin-top: mycalcSP(40);
    margin-bottom: mycalcSP(24);
  }

  &_link {
    position: relative;

    &_txt {
      font-size: inherit;
      line-height: inherit;
    }

    /*
    &:before {
      content: "";
      width: 100%;
      border-bottom: 1px solid $c_white;
      opacity: 0;
      right: 0;
      transform: scaleX(0);
      display: block;
      position: absolute;
      transition: transform $t_slide, opacity $t_slide;
      @include responsive((
        bottom: -6,
      ));
      @include mq(sp) {
        bottom: mycalcSP(-6);
      }
    }

    &:hover {
      &:before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
    */
  }
}


.footer_pagetop {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  @include responsive((
    width: 96,
    height: 96,
    right: 48,
  ));
  @include mq(sp) {
    width: mycalcSP(96);
    height: mycalcSP(96);
    border-width: mycalcSP(1);
    position: absolute;
    right: mycalcSP(24);
    bottom: mycalcSP(22);
  }

  &_link {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 50%;
    @include responsive((
      top: 0,
      right: 0,
    ));
    @include mq(sp) {
      top: 0;
      right: 0;
    }

    .ico_circle {
      width: 100%;
      height: 100%;
      transition: transform $t_btn;

      circle {
        transition: opacity $t_btn, stroke-dashoffset $t_btn;
        stroke-dashoffset: 0;
        stroke-dasharray: 380;
        stroke: $c_gray;
      }
    }

    .ico_arw {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 50%;
      @include responsive((
        width: 16,
        height: 16,
        margin-right: -8,
        margin-top: -8,
      ));
      @include mq(sp) {
        width: mycalcSP(16);
        height: mycalcSP(16);
        margin-right: mycalcSP(-8);
        margin-top: mycalcSP(-8);
      }

      path {
        stroke: $c_white;
      }
    }

    &:hover {
      .ico_circle {
        transform: scale(1.066);

        circle {
          opacity: 1;
          stroke-dashoffset: 760;
          /* // 1280以下で一部切れてしまうので固定値で設定
          @include responsive((
            stroke-dashoffset: 1520,
          ));
          */
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(1520);
          }
        }
      }

      .ico_arw {
        transform: scaleX(1.06);

        path {
          @include responsive((
            stroke-dashoffset: -160,
          ));
          @include mq(sp) {
            stroke-dashoffset: mycalcSP(-160);
          }
        }
      }
    }
  }
}


.footer_copyright {
  font-family: $f_en;
  font-weight: 400;
  letter-spacing: .1em;
  text-align: right;
  @include responsive((
    font-size: 11,
    line-height: 20,
    padding-bottom: 10,
  ));
  @include mq(sp) {
    text-align: left;
    width: 100%;
    font-size: mycalcSP(10);
    line-height: mycalcSP(10);
  }
}


.footer_offer {
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  color: $c_white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  @include responsive((
    height: 878,
    margin-bottom: 264,
  ));
  @include mq(sp) {
    height: auto;
    flex-wrap: wrap;
    margin-bottom: mycalcSP(120);
  }

  &_figure {
    position: absolute;
    top: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    transition: transform $t_curtain, opacity $t_curtain;
    @include responsive((
      width: 1560,
      height: 878,
    ));
    @include mq(sp) {
      position: absolute;
      width: mycalcSP(366);
      height: mycalcSP(410);
    }

    .footer_offer_catalog & {
      left: 0;
      clip-path: inset(0 50% 0 0);
      @include mq(sp) {
        clip-path: inset(0 0 0 0);
      }
    }

    .footer_offer_contact & {
      right: 0;
      clip-path: inset(0 0 0 50%);
      @include mq(sp) {
        clip-path: inset(0 0 0 0);
      }
    }

    &_img {
      background: $c_hyacinth_light;
      max-width: none;
      position: absolute;
      top: 0;
      opacity: 0;
      transform: scale(1.1);
      transition: transform $t_curtain .2s, opacity $t_curtain .2s;
      height: 100%;
      @include responsive((
        width: 1560,
      ));
      @include mq(sp) {
        width: mycalcSP(366);
      }

      .footer_offer_catalog & {
        left: 0;
      }

      .footer_offer_contact & {
        right: 0;
      }

      .is-active & {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &_inner {
    z-index: 0;
    box-sizing: border-box;
    position: relative;
    @include responsive((
      width: 780,
      height: 878,
    ));
    @include mq(sp) {
      width: mycalcSP(366);
      height: mycalcSP(410);
    }

    .headline {
      position: absolute;
      @include responsive((
        width: 588,
        top: 92,
      ));
      @include mq(sp) {
        position: relative;
        width: 100%;
        top: 0;
        right: auto;
      }

      &_jp {
        color: $c_white;
        @include responsive((
          font-size: 16,
          line-height: 16,
          margin-bottom: 24,
        ));
        @include mq(sp) {
          font-size: mycalcSP(12);
          line-height: mycalcSP(16);
          margin-bottom: mycalcSP(16);
        }
      }
      &_en {
        color: $c_white;
        @include responsive((
          font-size: 56,
          line-height: 56,
        ));
        @include mq(sp) {
          font-size: mycalcSP(36);
          line-height: mycalcSP(32);
        }
      }
    }

    .btn_round_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      @include responsive((
        bottom: 80,
      ));
      @include mq(sp) {
        bottom: mycalcSP(40);
      }
    }

    &.footer_offer_catalog {
      .headline {
        @include responsive((
          left: 96,
        ));
        @include mq(sp) {
          left: 0;
        }
      }

      .btn_round_wrap {
        left: 0;
      }
    }

    &.footer_offer_contact {
      .headline {
        @include responsive((
          right: 96,
        ));
        @include mq(sp) {
          left: 0;
          right: auto;
        }
      }

      .btn_round_wrap {
        right: 0;
      }
    }
  }

  &_link {
    display: block;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    z-index: 0;
    @include responsive((
      width: 780,
      padding: 92 96 80,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(64 23 40);
    }

    &:before {
      content: "";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity $t_curtain;

      .footer_offer_catalog & {
        right: 0;
        border-right: solid $c_white;
        @include responsive((
          border-right-width: 1,
        ));
      }

      .footer_offer_contact & {
        left: 0;
        border-left: solid $c_white;
        @include responsive((
          border-left-width: 1,
        ));
      }

      @include mq(sp) {
        content: none;
      }
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      + .footer_offer_figure {
        .footer_offer_figure_img {
          transition-delay: 0s;
          transform: scale(1.2);
        }
      }
    }
  }

  &_mirror {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    opacity: .5;

    .footer_offer_catalog & {
      right: 0;
    }

    .footer_offer_contact & {
      left: 0;
    }

    .btn_round_link {
      background: none;
      border: 1px solid $c_white;
      white-space: nowrap;
    }

    @include mq(sp) {
      display: none;
    }
  }

}
