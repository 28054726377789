.technology_feature {
  &_point {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive((
      width: 544,
      height: 528,
      margin: 88 auto 272,
    ));
    @include mq(sp) {
      width: mycalcSP(320);
      height: mycalcSP(336);
      margin: mycalcSP(56 auto 128);
    }

    &:before {
      content: "";
      box-sizing: border-box;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      border: solid $c_gray;
      position: absolute;
      @include responsive((
        width: 400,
        height: 400,
        border-width: 1,
        margin-left: -200,
        margin-top: -200,
      ));
      @include mq(sp) {
        width: mycalcSP(240);
        height: mycalcSP(240);
        border-width: mycalcSP(1);
        margin-left: mycalcSP(-120);
        margin-top: mycalcSP(-120);
      }
    }

    &_ttl {
      font-weight: 700;
      text-align: center;
      @include responsive((
        font-size: 24,
        line-height: 40,
      ));
      @include mq(sp) {
        font-size: mycalcSP(20);
        line-height: mycalcSP(32);
      }
    }

    &_item {
      border-radius: 50%;
      box-sizing: border-box;
      border: solid $c_red;
      background: $c_white;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      transition: transform $e_curtain .5s, opacity $e_curtain .4s;
      @include responsive((
        border-width: 3,
        width: 160,
        height: 160,
        margin-top: -80,
        margin-left: -80,
      ));
      @include mq(sp) {
        border-width: mycalcSP(3);
        width: mycalcSP(104);
        height: mycalcSP(104);
        margin-top: mycalcSP(-52);
        margin-left: mycalcSP(-52);
      }

      .is-active & {
        opacity: 1;
      }

      &_wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        transform: rotate(180deg);
        transition: transform $e_curtain .9s;

        .is-active & {
          transform: rotate(360deg);
        }
      }

      &:before {
        content: "";
        border-radius: 50%;
        opacity: .05;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        @include responsive((
          width: 178,
          height: 178,
          margin-top: -89,
          margin-left: -89,
        ));
        @include mq(sp) {
          width: mycalcSP(120);
          height: mycalcSP(120);
          margin-top: mycalcSP(-60);
          margin-left: mycalcSP(-60);
        }
      }
      &:after {
        content: "";
        position: absolute;
        background: $c_white;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
      }

      &.heat {
        transition-delay: 0s;
        border-color: $c_heat;

        &:before {
          background: $c_heat;
        }

        .is-active & {
          @include responsive((
            transform-translate: 0 -184,
          ));
          @include mq(sp) {
            transform: translate(#{mycalcSP(0)}, #{mycalcSP(-126)});
          }
        }
      }

      &.insulation {
        transition-delay: .05s;
        border-color: $c_insulation;

        &:before {
          background: $c_insulation;
        }

        .is-active & {
          @include responsive((
            transform-translate: 192 -24,
          ));
          @include mq(sp) {
            transform: translate(#{mycalcSP(108)}, #{mycalcSP(-36)});
          }
        }
      }

      &.vacuum {
        transition-delay: .1s;
        border-color: $c_vacuum;

        &:before {
          background: $c_vacuum;
        }

        .is-active & {
          @include responsive((
            transform-translate: 112 184,
          ));
          @include mq(sp) {
            transform: translate(#{mycalcSP(-64)}, #{mycalcSP(126)});
          }
        }
      }

      &.magnetic {
        transition-delay: .15s;
        border-color: $c_magnetic;

        &:before {
          background: $c_magnetic;
        }

        .is-active & {
          @include responsive((
            transform-translate: -112 184,
          ));
          @include mq(sp) {
            transform: translate(#{mycalcSP(64)}, #{mycalcSP(126)});
          }

          .stainless-bearings & {
            @include responsive((
              transform-translate: 176 72,
            ));
            @include mq(sp) {
              transform: translate(#{mycalcSP(108)}, #{mycalcSP(34)});
            }
          }
        }
      }

      &.corrosion {
        transition-delay: .2s;
        border-color: $c_corrosion;

        &:before {
          background: $c_corrosion;
        }

        .is-active & {
          @include responsive((
            transform-translate: -192 -24,
          ));
          @include mq(sp) {
            transform: translate(#{mycalcSP(-108)}, #{mycalcSP(-36)});
          }

          .stainless-bearings & {
            @include responsive((
              transform-translate: -176 72,
            ));
            @include mq(sp) {
              transform: translate(#{mycalcSP(-108)}, #{mycalcSP(34)});
            }
          }
        }
      }

      &_name {
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-weight: 700;
        z-index: 2;
        @include responsive((
          font-size: 20,
          line-height: 40,
        ));
        @include mq(sp) {
          font-size: mycalcSP(18);
          line-height: mycalcSP(24);
        }
      }

      &_ico {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;

        svg {
          width: auto;
          opacity: .1;
          @include responsive((
            height: 80,
          ));
          @include mq(sp) {
            height: mycalcSP(56);
          }
        }
      }

      &_name,
      &_ico {
        transform: rotate(-180deg);
        transition: transform $e_curtain .9s;

        .is-active & {
          transform: rotate(-360deg);
        }
      }
    }
  }
}


.technology_merit {
  position: relative;

  &_list {
    width: 100%;
    counter-reset: number;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      border-bottom: 1px solid $c_gray;
      counter-increment: number;
      @include responsive((
        padding-bottom: 64,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        justify-content: center;
        padding-bottom: mycalcSP(40);
        margin-bottom: mycalcSP(40);
      }
      &:last-child {
        margin-bottom: 0;
      }

      &_ico {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $c_white;
        border-radius: 50%;
        position: relative;
        @include responsive((
          width: 200,
          height: 200,
          margin: 8 0 0 8,
        ));
        @include mq(sp) {
          width: mycalcSP(160);
          height: mycalcSP(160);
          margin: mycalcSP(0 auto 24);
        }

        &:before {
          content: "";
          position: absolute;
          background: $c_red;
          border-radius: 50%;
          opacity: .05;
          z-index: -1;
          @include responsive((
            width: 216,
            height: 216,
            top: -8,
            left: -8,
          ));
          @include mq(sp) {
            width: mycalcSP(176);
            height: mycalcSP(176);
            top: mycalcSP(-8);
            left: mycalcSP(-8);
          }

          .corrosion & {
            background: $c_corrosion;
          }

          .rotation & {
            background: $c_rotation;
          }

          .vacuum & {
            background: $c_vacuum;
          }

          .heat & {
            background: $c_heat;
          }

          .magnetic & {
            background: $c_magnetic;
          }
        }

        &:after {
          content: counter(number, decimal-leading-zero);
          color: $c_red;
          font-family: $f_en;
          position: absolute;
          @include responsive((
            font-size: 32,
            line-height: 32,
            top: -8,
            left: -8,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(24);
            top: mycalcSP(-8);
            left: mycalcSP(-8);
          }
        }

        svg {
          width: auto;
          @include responsive((
            height: 80,
          ));
          @include mq(sp) {
            height: mycalcSP(56);
          }
        }
      }

      &_info {
        @include responsive((
          width: 894,
        ));
        @include mq(sp) {
          width: 100%;
        }
      }

      &_mark {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
        @include responsive((
          margin-bottom: 16,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(8);
        }
        
        mark {
          display: flex;
          color: $c_white;
          background: $c_red;
          @include responsive((
            font-size: 16,
            line-height: 16,
            padding: 8 32,
            border-radius: 24,
            margin-right: 8,
            margin-bottom: 8,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(14);
            padding: mycalcSP(8 24);
            border-radius: mycalcSP(24);
            margin-right: mycalcSP(8);
            margin-bottom: mycalcSP(8);
          }

          &.corrosion,
          .corrosion & {
            background: $c_corrosion;
          }

          &.rotation,
          .rotation & {
            background: $c_rotation;
          }

          &.vacuum,
          .vacuum & {
            background: $c_vacuum;
          }

          &.heat,
          .heat & {
            background: $c_heat;
          }

          &.magnetic,
          .magnetic & {
            background: $c_magnetic;
          }

          &.insulation,
          .insulation & {
            background: $c_insulation;
          }
        }
      }

      &_ttl {
        font-weight: 700;
        @include responsive((
          font-size: 24,
          line-height: 40,
          margin-bottom: 40,
        ));
        @include mq(sp) {
          font-size: mycalcSP(18);
          line-height: mycalcSP(28);
          margin-bottom: mycalcSP(24);
        }

        small {
          font-weight: inherit;
          @include responsive((
            font-size: 20,
            line-height: 40,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(28);
          }
        }
      }

      &_data {
        &_ttl {
          font-weight: 700;
          position: relative;
          @include responsive((
            font-size: 20,
            line-height: 32,
            margin-bottom: 8,
            padding-left: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(16);
            line-height: mycalcSP(32);
            margin-bottom: mycalcSP(8);
            padding-left: mycalcSP(16);
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            border-radius: 50%;
            background: $c_hyacinth;
            @include responsive((
              width: 8,
              height: 8,
              top: 14,
            ));
            @include mq(sp) {
              width: mycalcSP(8);
              height: mycalcSP(8);
              top: mycalcSP(14);
            }
          }

        }
        &_desc {
          font-weight: 400;
          @include responsive((
            font-size: 16,
            line-height: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(14);
            line-height: mycalcSP(24);
          }
        }
      }
    }
  }

  &_next {
    &_bg {
      .ceramic-bearings & {
        background-image: url("../images/technology/ceramic-bearings/merit_bg.jpg");
        @include mq(sp) {
          background-image: url("../images/technology/ceramic-bearings/merit_bg_sp.jpg");
        }
      }

      .stainless-bearings & {
        background-image: url("../images/technology/stainless-bearings/merit_bg.jpg");
        @include mq(sp) {
          background-image: url("../images/technology/stainless-bearings/merit_bg_sp.jpg");
        }
      }
    }
  }
}


.technology_type {
  position: relative;

  &_list {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @include mq(sp) {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  &_item {
    position: relative;
    border-top: solid $c_gray;
    @include responsive((
      width: 560,
      border-top-width: 1,
      padding: 24 0 64,
    ));
    @include mq(sp) {
      width: 100%;
      border-top-width: mycalcSP(1);
      padding: mycalcSP(24 0 40);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      background: $c_red;
      @include responsive((
        width: 96,
        height: 1,
        top: -1,
      ));
      @include mq(sp) {
        width: mycalcSP(64);
        height: mycalcSP(1);
        top: mycalcSP(-1);
      }
    }

    &_ttl {
      font-weight: 700;
      @include responsive((
        font-size: 20,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(16);
        line-height: mycalcSP(24);
        margin-bottom: mycalcSP(16);
      }
    }

    &_desc {
      font-weight: 400;
      @include responsive((
        font-size: 16,
        line-height: 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
      }

      strong {
        font-weight: 700;
      }
      
      hr {
        background: none;
        height: 0;
        margin-bottom: 0;
        @include responsive((
          margin-top: 16,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(10);
        }
      }
    }
  }

  &_next {
    &_bg {
      .ceramic-bearings & {
        background-image: url("../images/technology/ceramic-bearings/dl_bg.jpg");
        @include mq(sp) {
          background-image: url("../images/technology/ceramic-bearings/dl_bg_sp.jpg");
        }
      }

      .stainless-bearings & {
        background-image: url("../images/technology/stainless-bearings/dl_bg.jpg");
        @include mq(sp) {
          background-image: url("../images/technology/stainless-bearings/dl_bg_sp.jpg");
        }
      }
    }

    .btn_round {
      @include responsive((
        height: 56,
      ));
      @include mq(sp) {
        height: mycalcSP(56);
      }
    }
  }
}