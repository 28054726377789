.thanks,
.notfound {

  &_wrap {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    @include responsive((
      margin-bottom: 264,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(120);
    }
  }

  .contents_intro {
    margin-bottom: 0;
  }

  &_contents {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid $c_gray;
    box-sizing: border-box;
    @include responsive((
      max-width: 1200,
      padding: 96 204,
    ));
    @include mq(sp) {
      max-width: mycalcSP(342);
      padding: mycalcSP(48 0 64);
    }
    
    p {
      @include responsive((
        font-size: 18,
        line-height: 40,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(15);
        line-height: mycalcSP(30);
        margin-bottom: mycalcSP(16);
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $c_red;
    }
  }

  .btn {
    @include responsive((
      margin-top: 96,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(64);
    }
  }
}