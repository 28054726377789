.page,
.column,
.not_found,
.news {

  &_archive {
    @include responsive((
      margin-bottom: 74,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: mycalcSP(80);
    }

    .ttl {
      align-items: flex-start;
      @include responsive((
        padding: 120 0 52,
      ));
      @include mq(sp) {
        padding: mycalcSP(96 0 14);
      }

      &_ja {
        @include responsive((
          margin-top: 20,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(10);
        }
      }

      &_en {
        color: $c_gray;
        letter-spacing: 0;
        position: relative;
        top: 0;
        left: 0;
        @include responsive((
          font-size: 160,
          line-height: 160,
        ));
        @include mq(sp) {
          font-size: mycalcSP(48);
          line-height: mycalcSP(48);
        }

      }
    }

    &_term {
      letter-spacing: .05em;
      font-family: $f_en;
      position: relative;
      @include responsive((
        font-size: 24,
        line-height: 32,
        padding-left: 40,
        margin-bottom: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(32);
        padding-left: mycalcSP(20);
        margin-bottom: mycalcSP(32);
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        background: $c_red;
        border-radius: 50%;
        @include responsive((
          width: 4,
          height: 4,
          left: 24,
          top: 13,
        ));
        @include mq(sp) {
          width: mycalcSP(4);
          height: mycalcSP(4);
          left: 0;
          top: mycalcSP(13);
        }
      }
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      @include responsive((
        width: 787,
        padding: 0 24,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(0);
      }

      .box_card {
        @include responsive((
          margin: 0 72 64 0,
        ));
        @include mq(sp) {
          width: 100%;
          margin: mycalcSP(0 0 48);

          &:last-child {
            margin-bottom: 0;
          }
        }

        &_thumb {
          @include mq(sp) {
            width: 100%;
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

.category {
  box-sizing: border-box;
  @include responsive((
    width: 300,
    margin-right: 51,
  ));
  @include mq(sp) {
    width: 100%;
    margin: mycalcSP(0 0 64);
    padding: 0;
    border-bottom: 1px solid $c_border;
  }

  &_name {
    display: none;
    @include mq(sp) {
      display: block;
      font-size: mycalcSP(20);
      line-height: mycalcSP(20);
      letter-spacing: .1em;
      font-family: $f_en;
      position: relative;
    }
  }

  &_selected {
    display: none;
    @include mq(sp) {
      display: block;
      position: relative;
      margin-top: mycalcSP(32);

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: mycalcSP(20);
        height: mycalcSP(1);
        background: $c_border;
        top: mycalcSP(12);
      }
      &:before {
        right: 0;
      }
      &:after {
        width: mycalcSP(16);
        right: mycalcSP(2);
        transform: rotate(90deg);
        transition: transform $t_slide;
      }

      &.open {
        &:after {
          transform: rotate(0);
        }
      }
    }
  }

  &_box {
    @include mq(sp) {
      display: none;
    }
  }

  &_list {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      padding: 0 24,
    ));
    @include mq(sp) {
      padding: 0;
    }

    &_item {
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: 0;
        padding-bottom: mycalcSP(24);
      }

      &.all {
        order: -1;
      }

      &.selected {
        @include mq(sp) {
          .category_box & {
            display: none;
          }
        }
      }

      &_link {

      }

      &_ttl {
        display: block;
        position: relative;
        @include responsive((
          font-size: 18,
          line-height: 24,
          padding-left: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(16);
          line-height: mycalcSP(24);
          padding-left: mycalcSP(16);
        }

        &:before {
          content: "";
          position: absolute;
          border-radius: 50%;
          border: 1px solid $c_red;
          left: 0;
          @include responsive((
            top: 9,
            width: 8,
            height: 8,
          ));
          @include mq(sp) {
            top: mycalcSP(9);
            width: mycalcSP(8);
            height: mycalcSP(8);
          }

          .selected & {
            background: $c_red;
          }
        }
      }
    }
  }
}