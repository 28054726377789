.catalog {
  &_list {
    border-top: 1px solid $c_gray;
    @include responsive((
      margin-top: 32,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(24);
    }

    &_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $c_gray;
      @include responsive((
        padding: 32 0,
      ));
      @include mq(sp) {
        padding: mycalcSP(24 0);
      }

      &_name {
        color: $c_navy;
        font-weight: bold;
        position: relative;
        margin-bottom: 0 !important;
        @include responsive((
          font-size: 20,
          line-height: 24,
          padding-left: 16,
        ));
        @include mq(sp) {
          width: 100%;
          font-size: mycalcSP(18);
          line-height: mycalcSP(22);
          padding-left: mycalcSP(16);
        }

        &:before {
          content: "";
          border-radius: 50%;
          background: $c_red;
          position: absolute;
          left: 0;
          @include responsive((
            width: 8,
            height: 8,
            top: 18,
          ));
          @include mq(sp) {
            width: mycalcSP(6);
            height: mycalcSP(6);
            top: mycalcSP(12);
          }
        }
      }

      .btn_round {
        @include responsive((
          width: 224,
          height: 56,
        ));
        @include mq(sp) {
          width: mycalcSP(160);
          height: mycalcSP(48);
          margin-top: mycalcSP(24);
          justify-self: flex-end;
          align-self: flex-end;
          margin-right: 0;
        }

        .btn_round_link_txt {
          @include mq(sp) {
            font-size: mycalcSP(14);
          }
        }
      }
    }
  }
}