/*
 * breadcrumb.scss
 *
 * パンクズリスト用コンポーネント
 */

.breadcrumb {
  width: 100%;
  box-sizing: border-box;
  @include responsive((
    // margin-top: 64,   //_txtのpadding-bottom分を減算
    margin-top: 44,
  ));
  @include mq(sp) {
    margin-top: 0;
    order: -1;
  }

  &_list {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    line-height: 1;
    @include flex(row, flex-end, center, none, wrap);

    &_item {
      position: relative;
      color: $c_text;

      &:not(:last-child) {
        @include responsive((
          margin-right: 96,
        ));
        @include mq(sp) {
          margin-right: mycalcSP(40);
        }

        &:after {
          content: "";
          background: $c_gray;
          position: absolute;
          display: block;
          top: 50%;
          @include responsive((
            width: 64,
            height: 1,
            right: -80,
          ));
          @include mq(sp) {
            width: mycalcSP(24);
            height: mycalcSP(1);
            right: mycalcSP(-32);
          }
        }
      }

      &_link {
        color: $c_hyacinth;
        position: relative;
      }

      &_txt {
        color: inherit;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        @include responsive((
          max-width: 400,
          font-size: 16,
          line-height: 20,
        ));
        @include mq(sp) {
          max-width: mycalcSP(120);
          font-size: mycalcSP(10);
          line-height: mycalcSP(12);
        }
      }
    }
  }
}