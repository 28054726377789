// cursor styling

/*
html,
body,
a{
  cursor: none;
}
*/

.cursor {
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  will-change: transform;
  pointer-events: none;
  z-index: 1002;
  background: $c_black;
  border-radius: 50%;
  transition:
    transform $e_curtain .2s,
    opacity linear .4s,
    width linear .4s,
    height linear .4s;

  // ポインター用の要素は、画面の左上に位置するよう設定
  &.active_swipe {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
    // position: absolute;
    // transform-origin: center;
    opacity: 1;
    @include responsive((
      width: 120,
      height: 120,
      margin-top: -60,
      margin-left: -60,
    ));
    
    &:before {
      content: "swipe";
      font-family: $f_en;
      color: $c_white;
      @include responsive((
        font-size: 16,
        line-height: 16,
      ));
    }

    &:after {
      content: "";
      background-image: url("../images/top/arw.png");
      position: absolute;
      top: 50%;
      left: 50%;
      @include responsive((
        margin-top: -4,
        margin-left: -104.5,
        width: 209,
        height: 9,
        background-size: 209 9,
      ));
    }
  }
}

